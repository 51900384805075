import { Data as DataClass } from "./Data";

export const Data = new DataClass({theme:"dark"}); // NONE of the data is synced. It's all private fields

export function mobile()
{
	console.log(window.innerWidth);
	return window.innerWidth <= 800;
}

// Detect Back Button, if iOS, disable animation. (Back button is part of Routing)
export function iOS() {
	return [
	  'iPad Simulator',
	  'iPhone Simulator',
	  'iPod Simulator',
	  'iPad',
	  'iPhone',
	  'iPod'
	].includes(navigator.platform)
	// iPad on iOS 13 detection
	|| (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }