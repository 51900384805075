import React from 'react';
import { Page } from '../Page';
import { Column } from '../../Column';
import { Row } from '../../Row';
import { Gallery } from '../../Gallery';

import Header from '../../../Assets/Images/feature_graphic.svg'
import { ResponsiveRowColumn } from '../../ResponsiveRowColumn';
import { LocalizedLabel } from '../LocalizedLabel';

export function Productivity()
{
	return <Page url="/Productivity">

		<Column className="page_content" style={{ color: "var(--c_d)", position: 'relative', marginTop: 60, overflow: 'initial', padding: 0 }}>
			<Column style={{ width: "100%", height: "100%", overflowY: 'auto', padding: 8 }}>
				<Row style={{ justifyContent: 'center' }}>
					<h1>GD2CS</h1>

				</Row>



				<div style={{ textAlign: 'center',marginBottom:24 }} className="card">
					<LocalizedLabel text={{
						de:
							<span>
								"GD2CS" ist ein Python Konsolentool und/oder eine <a rel="noreferrer" href={"https://godotengine.org/"} target="_blank">Godot</a> Erweiterung, mit der GdScript zu C# konvertiert werden kann.
							</span>,
						en:
							<span>
								"GD2CS" is a python command line tool and/or a <a rel="noreferrer" href={"https://godotengine.org/"} target="_blank">Godot</a> plugin for converting gdscript to c#.
							</span>
					}} />

				</div>
				<ResponsiveRowColumn style={{ alignItems: 'center', maxWidth:'calc(100% - 24px)', minHeight:400 }}>{/*display: 'grid', gridAutoRows: '1fr', gridTemplateColumns: 'repeat(2,1fr)',  */}
					<LocalizedLabel className="card" style={{ height: '100%' }} text={{
						de:
							<div><span>
								<h2>Details</h2>
								Dieses Projekt entstand, als ich eines meiner hobby Projekte von GdScript zu C# konvertieren wollte.<br />
								Es funktioniert komplett per <a rel="noreferrer" href={"https://en.wikipedia.org/wiki/Regular_expression"} target="_blank">Regex</a>, und war ursprünglich ein Übungsprojekt, mit dem ich mir Regex beibringen wollte.<br />
								Originalerweise war es ein Python Command Line Tool, aber nachdem es besser funktionierte, als zuerst erwartet, implementierte ich es als graphisches Godot Plugin um es einer größeren Zielaudienz verfügbar zu machen.<br /><br />
								Download auf <a rel="noreferrer" href={"https://github.com/kiriri/gd2cs.py"} target="_blank">Github</a> oder <a rel="noreferrer" href={"https://godotengine.org/asset-library/asset/849"} target="_blank">Godot Asset Library</a>
							</span></div>,
						en:
							<div style={{ flexGrow: 1 }}><span>
								<h2>About</h2>
								I needed to convert a personal project from gdscript to c#, so I made this.<br />
								It's entirely <a rel="noreferrer" href={"https://en.wikipedia.org/wiki/Regular_expression"} target="_blank">regex</a> driven, because I wanted to teach myself how to write complex regex replacements.<br />
								It was initially meant as a python tool only, but it turned out much better than expected, so I tried to share it more widely and turned it into a Godot plugin.<br /><br />
								Get it on <a rel="noreferrer" href={"https://github.com/kiriri/gd2cs.py"} target="_blank">Github</a> or <a rel="noreferrer" href={"https://godotengine.org/asset-library/asset/849"} target="_blank">Godot Asset Library</a>
							</span></div>
					}} />
					<Gallery images={["Images/Productivity/gd2cs1.png", "Images/Productivity/showcase_1.gif"]} />
				</ResponsiveRowColumn>

			</Column>
		</Column>


	</Page>;
}
