import { Component } from "react";
import "./Footer.scss";
import { merge_props } from '../../Helpers/ReactHelper';
import arrow from '../../Assets/Images/back-arrow.svg'
import history from '../../Helpers/History';
import $ from 'jquery';
import './BackgroundAnimatedWater.scss'
import React from "react";
import { perlin } from "../../Helpers/Perlin";
import { AnimatedBackground, Color, interpolate_colors, rgba } from "./AnimatedBackground";

export interface BackgroundAnimatedWaterState
{
	data: number[][],
	char_width: number,
	char_height: number,
	focused: boolean,
	time: number,
	theme: number,
	intro_animation: number,
	bubbles: [number, number][]
}


export class BackgroundUnderwater extends AnimatedBackground
{
	static update_rate = 0.25;

	state: BackgroundAnimatedWaterState;
	_update_id: any = -1;
	canvas = React.createRef<HTMLCanvasElement>();

	constructor(props: any)
	{
		super(props);

		this.state = {
			data: [],
			char_width: 0,
			char_height: 0,
			focused: true,
			time: Date.now()/1000.0,
			theme: -1,
			intro_animation: -1,
			bubbles: [],
		};
	}


	update_data()
	{
		if(!this.state.focused)
			return;
			
		const speed = 0.25;

		let theme = Number.parseFloat(getComputedStyle(document.body).getPropertyValue('--theme'));

		var canvas = this.canvas.current!;
		if(canvas == undefined)
			return;
		var FONT_SIZE = this.get_font_size(canvas);


		let update = this.state.char_height === 0;

		if (update)
		{
			this.init(canvas);
		}



		// if (this.state.intro_animation> 0.5)
		// 	return;

		var ctx = canvas.getContext("2d")!;

		let height = canvas.height! / FONT_SIZE
		let width = canvas.width! / this.state.char_width

		if (update)
		{
			this.state.bubbles = [];
			for (let i = 0; i < width; i++)
			{
				if (Math.random() < 0.2)
					this.state.bubbles.push([i, Math.random() * height]);
			}
		}

		ctx.font = (theme >= 50 ? 'bold ' : '') + FONT_SIZE + "px monospace";

		let colors = AnimatedBackground.get_colors(theme/100.0);//colors_dark.map((v, i) => interpolate_colors(v, colors_light[i], theme / 100));
		let colors_string = Object.keys(colors).map(v => rgba(colors[v]));

		ctx.fillStyle = colors_string[0];

		let intro_animation = Math.max(Math.min(this.state.time / 3, 1), 0);

		//ctx.clearRect(0, 0, canvas.width, canvas.height);


		const draw_background = (x:number,y:number,length:number) =>
		{
			let color_background: Color = [...colors.water];
			color_background[3] = Math.floor(intro_animation * 255 * (1 - y / height));
			let color_background_string = rgba(color_background);

			ctx.fillStyle = color_background_string;
			ctx.clearRect(x * this.state.char_width, (y-1) * FONT_SIZE - 1, this.state.char_width * length, FONT_SIZE + 2);
			ctx.fillText("~".repeat(length), x * this.state.char_width, y * FONT_SIZE);
		}

		const draw_bubble = (prev_x:number,prev_y:number,x:number,y:number)=>
		{
			x = Math.floor(x);
			y = Math.floor(y);
			prev_x = Math.floor(prev_x);
			prev_y = Math.floor(prev_y);
			if(prev_x == x && prev_y == y)
				return;

			draw_background(prev_x,prev_y,1);
			

			let color_background: Color = [...colors.water];
			color_background[3] = Math.floor(intro_animation * 255 * (1 - y / height));
			let color_background_string = rgba(color_background);

			ctx.fillStyle = color_background_string;


			//console.log(x,(height - Math.floor(y) - 1))

			ctx.clearRect(x * this.state.char_width, (Math.floor(y) - 1) * FONT_SIZE + 3, this.state.char_width, FONT_SIZE);
			ctx.fillText("o", x * this.state.char_width, (Math.floor(y) ) * FONT_SIZE);
		}

		if(update || (this.state.theme != theme) )
			// Draw background (static, unless theme variable changed)
			for (let y = 0; y < height; y++)
			{
				draw_background(0,y,Math.ceil(width));
			}

		// Draw bubbles
		for (let i = 0; i < this.state.bubbles.length; i++)
		{
			let [prev_x, prev_y] = this.state.bubbles[i];
			this.state.bubbles[i][1]-=speed;
			if (this.state.bubbles[i][1] < 0)
			{
				this.state.bubbles[i] = [Math.floor(Math.random() * width ), height + Math.random() * 5]
			}
			if (this.state.bubbles[i][1] >= height)
				continue;
			
			let [x, y] = this.state.bubbles[i];

			draw_bubble(prev_x,prev_y,x,y);
		}

		this.state.theme = theme;
		this.state.intro_animation = intro_animation;
	}

	render()
	{
		return <canvas ref={this.canvas} className="background-animated-water-canvas" style={{ height:'100%', position:'absolute', top:0}}></canvas>
	}
}
