import { obj } from "./TypescriptHelper";

export class CookieHelper
{
	static get_cookies():obj<any>
	{
		return JSON.parse(document.cookie || "{}") || {};
	}

	static set_cookies(value:obj<any>)
	{
		document.cookie = JSON.stringify(value) + ";sameSite=Lax"; 
	}

	static get_cookie(name:string):any
	{
		return this.get_cookies()[name];
	}

	static set_cookie(name:string, value:any)
	{
		document.cookie = JSON.stringify({...CookieHelper.get_cookies(),[name]:value}) + ";sameSite=Lax"; 
	}
}