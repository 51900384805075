import React from 'react';
import $ from 'jquery'
import { Page } from '../Page';
import { Column } from '../../Column';
import { Row } from '../../Row';
import './Project.css';
import history from '../../../Helpers/History';

import web_icon from "../../../Assets/Images/web.svg";
import { BackgroundAnimatedWater } from '../BackgroundAnimatedWater';
import { ThemeSpecificContent } from '../ThemeSpecificContent';

/**
 * Page
 * List some good example projects here. 
 */
export class Projects extends React.Component
{

	// componentDidMount()
	// {
	// 	this.unlisten_history = history.listen((a) => 
	// 	{
	// 		if(a.pathname == "/Projects")
	// 		{
	// 			this.rendered = true;
	// 			this.interval = setInterval(()=>{
	// 				html2canvas(document.body,{width:window.innerWidth,height:window.innerHeight}).then(function(canvas) {
	// 					$(`#website_tile img,canvas`).remove();
	// 					$(`#website_tile`)[0].appendChild(canvas);
	// 					//canvas = $(canvas);
	// 					let scale = canvas.width/300;
	// 					//canvas.width /= scale;
	// 					$(canvas).css("width",(canvas.width / scale) + 'px');
	// 					$(canvas).css("height",(canvas.height / scale) + 'px');
			
			
	// 					$(canvas).css("width",'100%');
	// 					$(canvas).css("height",'');
	// 					$(canvas).css("margin",'auto');
	// 				});
	// 			},3000)
	// 		}
	// 		else if(this.rendered) // if unload is true, unload the page after it is no longer visible. Use this for performance heavy pages
	// 		{
	// 			clearInterval(this.interval);
	// 			//this.on_unload()
	// 		}
	// 	})
	// }

	// componentWillUnmount()
	// {
	// 	this.unlisten_history();
	// }


	render()
	{
		return <Page url="/Projects">
			<Column className="page_content" style={{ color: "var(--c_d)" }}>
				<Row style={{ justifyContent: "center" }}><h1>Showcase</h1></Row>
				<Row style={{ justifyContent: 'center' }}>

					<div id="grid_test">

						<div id="website_tile" onClick={() => { history.push('/React' + history.location.search) }} className="grid_box grid_box_hover" style={{display:'flex'}} >
							<BackgroundAnimatedWater/>
							<div className="grid_box_label"><span>This Website (React)</span></div>
						</div>

						<div className="grid_box" style={{ position: 'relative' }} >
							<div onClick={() => { history.push('/qyu' + history.location.search) }} className="grid_box_hover grid_box_small" style={{ position: 'absolute', left: "0%", width: '50%', height: '100%', backgroundColor: 'blue' }}>
								<ThemeSpecificContent dark={<img src={"/Images/react_qyu.svg"} />} light={<img src={"/Images/react_qyu_dark.svg"} />} style={{height:'100%',width:'100%'}}/>
								<div className="grid_box_label"><span>React Native</span></div>
							</div>
							<div style={{ position: 'absolute', left: '50%', display: 'inline-block', width: '50%', height: '100%' }}>
								<div className="grid_box_hover grid_box_small" style={{ width: '100%', height: '50%' }} onClick={() => { history.push('/cordova' + history.location.search) }}>
									<img src={"/Images/Metro/metro-tile.svg"} />
									<div className="grid_box_label"><span>Cordova</span></div>
								</div>
								<div className="grid_box_hover grid_box_small" style={{ width: '100%', height: '50%' }} onClick={() => { history.push('/unity' + history.location.search) }}>
								<ThemeSpecificContent dark={<img src={"/Images/Unity/unity_tile.svg"} />} light={<img src={"/Images/Unity/unity_tile_dark.svg"} />} style={{height:'100%',width:'100%'}}/>
									<div className="grid_box_label"><span>Unity</span></div>
								</div>
							</div>

						</div>

						<div className="grid_box grid_box_hover" onClick={() => { history.push('/CMS' + history.location.search) }} >
						<img src={"/Images/CMS/cms-tile.png"} />
						<div className="grid_box_label"><span>CMS Extensions</span></div>
						</div>

						<div className="grid_box grid_box_hover" onClick={() => { history.push('/Productivity' + history.location.search) }}>
							<img src={"/Images/Productivity/icon.svg"} />
							<div className="grid_box_label"><span>Tools & Productivity</span></div>
						</div>



					</div>
				</Row>
			</Column>
		</Page>;
	}
}
