
import React, { useState, useRef } from 'react';
import './Website.scss';

import { Intro } from './Pages/Intro';
import { Page } from './Page';
import { ThemeSwap } from './ThemeSwap';
import { BackButton } from './BackButton';
import { About } from './Pages/About';
import { Contact } from './Pages/Contact';
import { Projects } from './Pages/Projects';
import { Profession } from './Pages/Profession';
import { Qyu } from './Pages/Qyu';
import { Cordova } from './Pages/Cordova';
import { Unity } from './Pages/Unity';
import { Productivity } from './Pages/Productivity';
import { LanguageSwap } from './LanguageSwap';
import { Footer } from './Footer';
import history from '../../Helpers/History';
import { ReactPage } from './Pages/React';
import { CMS } from './Pages/CMS'; 
import { FullscreenGallery } from '../FullscreenGallery';
import { ScrollHint } from './ScrollHint';
import { Prices } from './Pages/Prices';

// What belongs on front page?
// Name, profession
// Some kind of intro transitions

  

export function Website()
{
	console.log("Rerendering")
	return (
		
		<div  className="web">
			<div id="header" style={{zIndex:5}}>
				<LanguageSwap/>
				<a href="/Contact" id="impressum_link" onClick={(e)=>{console.log(window.location.pathname);if(window.location.pathname != '/Contact') return history.go_to('/Contact' + history.location.search,"up",e)}}>Impressum</a>
				<ThemeSwap/>
			</div>
			
			<BackButton/>
			{/* <ScrollHint style={{position:'absolute', bottom : 40, left:'calc(50% - 20px)'}} /> */}

			

			{/* <div id="page_container" style={{flexGrow:1,overflowY:"scroll"}}> */}
			<Intro/>
			
			<About/>

			<Profession/>

			<Projects/>

			<Contact/>
			<Prices/>
			<ReactPage/>
			<Qyu/>
			<Cordova/>
			<Unity/>
			<Productivity/>
			<CMS/>
			
			<FullscreenGallery />
			
		</div>
	)
} 








