export function merge_props(propsA,props)
{
  for (var k in props)
  {
    switch(k)
    {
      case 'style':
        if (propsA.style == undefined)
          propsA.style = {};
        for (const key in props.style) 
        {
          propsA.style[key] = props.style[key];  
        }
        break;
      case 'className':
        propsA.className = propsA.className + " " + props.className;
        break;
      case 'onClick':
        if("onClick" in propsA)
        {
          var old_func = propsA.onClick;
          propsA.onClick = (...params)=>{old_func(...params); props.onClick(...params)};
          break
        }
      default:
        propsA[k] = props[k]; // default is overwrite
    }
  }
  

  return propsA;
}