import React, { Component, CSSProperties } from "react";
import { Column } from "../../Column";
import { Row } from "../../Row";
import './Intro.scss'
import './IntroCard.scss'
import history from '../../../Helpers/History';
import { Page } from "../Page";
import { LocalizedLabel } from "../LocalizedLabel";
import { find_closest_scrollable, ScrollHint } from "../ScrollHint";
import { BackgroundAnimatedWater } from "../BackgroundAnimatedWater";
import { BackgroundUnderwater } from "../BackgroundUnderwater";
import { ThemeSpecificContent } from "../ThemeSpecificContent";
import { transform } from "@babel/core";


class IntroCard extends Component<{ scroll_element: any }>
{
	componentDidMount()
	{
		window.addEventListener('resize', (e) => this.forceUpdate());
	}

	render()
	{
		if (window.innerWidth < 500)
		{
			return <div style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", flexDirection: "column", overflow: "hidden", position: 'relative' }}>
				<a href="/About" className={"link-row"} onClick={(e: any) => { return history.go_to('/About' + history.location.search, "left", e) }}>Sven Hollesen</a>
				<a href="#content" className={"link-row"} onClick={(e: any) => { e.preventDefault(); find_closest_scrollable(this.props.scroll_element.current!.container.current!)!.scrollTo({ top: window.innerHeight - 20, behavior: 'smooth' }); return false; }}><LocalizedLabel text={{ de: "Projekte", en: "Projects" }} /></a>
				<a href="/Contact" className={"link-row"} onClick={(e: any) => { return history.go_to('/Contact' + history.location.search, "up", e) }}><LocalizedLabel text={{ de: "Kontakt", en: "Contact" }} /></a>
			</div>
		}

		return <div className="intro-card" >
			<div id="intro-card-front" >
				<div style={{ height: "100%", width: "100%", border: "2px solid white", display: "flex", justifyContent: "center", flexDirection: "column", overflow: "hidden", position: 'relative' }}>
					<a id="title-sven" href="/About" className={"link-row"} onClick={(e: any) => { return history.go_to('/About' + history.location.search, "left", e) }}>Sven Hollesen</a>
					<a id="title-about" href="#content" className={"link-row"} onClick={(e: any) => { e.preventDefault(); find_closest_scrollable(this.props.scroll_element.current!.container.current!)!.scrollTo({ top: window.innerHeight - 20, behavior: 'smooth' }); return false; }}><LocalizedLabel text={{ de: "Projekte", en: "Projects" }} /></a>
					<a id="title-projects" href="/Contact" className={"link-row"} onClick={(e: any) => { return history.go_to('/Contact' + history.location.search, "up", e) }}><LocalizedLabel text={{ de: "Kontakt", en: "Contact" }} /></a>
					{/* 			
			<span id="title-sven">Sven Hollesen</span>
			<span id="title-about">About</span>
			<span id="title-projects">Projects</span> */}
					{/* <div style={{position:'absolute',width:400,height:400,borderRadius:400,background:"#168", bottom: -150, left:-200, zIndex:-1}}></div>
			<div style={{position:'absolute',width:400,height:400,borderRadius:400,background:"#888", bottom: -200, left:-250, zIndex:-1}}></div>
			<div style={{position:'absolute',width:400,height:400,borderRadius:400,background:"#168", bottom: -250, left:-300, zIndex:-1}}></div> */}

				</div>
			</div>
			<div id="intro-card-back" />
			<div id="intro-card-left" />
			<div id="intro-card-right" />
			<div id="intro-card-top" />
			<div id="intro-card-bottom" />
		</div>
	}
}

interface ImageRowHelperProps
{
	img: JSX.Element,
	text: JSX.Element,
	reverse?: boolean,
	design?: string,
}

const inViewport : IntersectionObserverCallback = (entries, observer) => {
	entries.forEach(entry => {
		console.log("Triggered Viewport");
	  entry.target.classList.toggle("is-inViewport", entry.isIntersecting);
	});
  };
const Obs = new IntersectionObserver(inViewport);
var counter = 0;
class ImageRowHelper extends Component<ImageRowHelperProps>
{
	element = React.createRef<Row>();
	counter = counter++;

	componentDidMount()
	{
		Obs.observe(document.getElementById(`ImageRowHelper${this.counter}`)!);
		  
	}

	componentWillUnmount()
	{
		Obs.unobserve(document.getElementById(`ImageRowHelper${this.counter}`)!)
	}

	render()
	{
		const design = "intro_9_slice";//this.props.design;
		const border_css = '0px solid rgb(200,200,200)'; //41 138 172
		const background = 'rgba(0,0,0,128)';
		const height = '20vmin';
		const box_style : CSSProperties = {
			width: '100%',
			height: height,
			borderRight: border_css, 
			borderBottom: border_css, 
			borderTop: border_css, 
			padding: 'min(20px,4vmin)', 
			display: 'flex', 
			justifyContent: 'center', 
			alignItems: 'center', 
			fontSize: 'max(3.3vmin,12px)', 
			color: 'white'
		}
		
		if (this.props.reverse)
		{
			return <Row ref={this.element} id={`ImageRowHelper${this.counter}`} className={(this.props.reverse ? "reverse " : "") +  "image-row-helper"} style={{ padding: "2.5vh", maxWidth: 'min(100vw,max(100vh,600px))' }}>
			<ThemeSpecificContent
					dark={<div className={design} style={box_style} >{this.props.text}</div>}
					light={<div className={design} style={{ ...box_style,filter: 'invert(1)', color:'lightsalmon' }} >{this.props.text}</div>}
					style={{ height, flexGrow: 1, margin: '2.5vh', marginLeft: '10vw', position: 'relative' }} />
			<div style={{ width: height, height }} className="image-row-helper-img">
				{this.props.img}
				<div className="glimmer"></div>
			</div>
		</Row>
		}
		return <Row ref={this.element} id={`ImageRowHelper${this.counter}`} className={(this.props.reverse ? "reverse " : "") +  "image-row-helper"} style={{ padding: "2.5vh", maxWidth: 'min(100vw,max(100vh,600px))' }}>
			<div style={{ width: height, height }} className="image-row-helper-img">
				{this.props.img}
				<div className="glimmer"></div>
			</div>
			<ThemeSpecificContent
					dark={<div className={design} style={box_style} >{this.props.text}</div>}
					light={<div className={design} style={{ ...box_style,filter: 'invert(1)', color:'lightsalmon' }}>{this.props.text}</div>}
					style={{ height, flexGrow: 1, margin: '2.5vh', marginRight: '10vw', position: 'relative' }} />
		</Row>
		// if (this.props.reverse)
		// {
		// 	return <Row ref={this.element} id={`ImageRowHelper${this.counter}`} className={(this.props.reverse ? "reverse " : "") +  "image-row-helper"} style={{ padding: "2.5vh", maxWidth: 'min(100vw,max(100vh,600px))' }}>
		// 		<div style={{ height, flexGrow: 1, margin: '2.5vh', marginLeft: '10vw', position: 'relative' }}>

		// 			<ThemeSpecificContent
		// 				dark={<div className={design} style={{ transform: 'scaleX(-100%)' }} />}
		// 				light={<div className={design} style={{ filter: 'brightness(0%)', transform: 'scaleX(100%)' }} />}
		// 				style={{ height: '100%', width: '100%' }} />

		// 			<div style={{ opacity: 1, left: 0, top: 0 }}>
		// 				<div style={{ position: 'absolute', top: 0, right: '0', width: '100%', height: '100%', borderRight: border_css, borderBottom: border_css, borderTop: border_css, padding: 'min(20px,4vmin)', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 'max(3.3vmin,12px)', color: 'white' }}>
		// 					{this.props.text}
		// 				</div>
		// 				<div style={{ position: 'absolute', top: 0, left: '-3.7vmin', width: '100%', height: '100%', transform: `skew(-${skew}deg)`, borderLeft: border_css, borderBottom: border_css }}></div>
		// 			</div>
		// 		</div>
		// 		<div style={{ width: height, height }} className="image-row-helper-img">
		// 			{this.props.img}
		// 			<div className="glimmer"></div>
		// 		</div>
		// 	</Row>
		// }
		// return <Row ref={this.element} id={`ImageRowHelper${this.counter}`} className={(this.props.reverse ? "reverse " : "") +  "image-row-helper"} style={{ padding: "2.5vh", maxWidth: 'min(100vw,max(100vh,600px))' }}>
		// 	<div style={{ width: height, height }} className="image-row-helper-img">
		// 		{this.props.img}
		// 		<div className="glimmer"></div>
		// 	</div>
		// 	<div style={{ height, flexGrow: 1, margin: '2.5vh', marginRight: '10vw', position: 'relative' }}>
		// 		{/* <div style={{ opacity: 0.5, left: 0, top: 0 }}>
		// 			<div style={{ position: 'absolute', background, top: 0, right: '0', width: '100%', height: '100%' }}></div>
		// 			<div style={{ position: 'absolute', background, top: 0, right: '-3.7vmin', width: '100%', height: '100%', transform: `skew(-${skew}deg)` }}></div>
		// 		</div> */}
		// 		<ThemeSpecificContent
		// 			dark={<div className={design} />}
		// 			light={<div className={design} style={{ filter: 'brightness(0%)' }} />}
		// 			style={{ height: '100%', width: '100%' }} />
		// 		<div style={{ opacity: 1, left: 0, top: 0 }}>
		// 			<div style={{ position: 'absolute', top: 0, right: '0', width: '100%', height: '100%', borderLeft: border_css, borderBottom: border_css, borderTop: border_css, padding: 'min(20px,4vmin)', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 'max(3.3vmin,12px)', color: 'white' }}>
		// 				{this.props.text}
		// 			</div>
		// 			<div style={{ position: 'absolute', top: 0, right: '-3.7vmin', width: '100%', height: '100%', transform: `skew(-${skew}deg)`, borderRight: border_css, borderTop: border_css }}></div>
		// 		</div>
		// 	</div>
		// </Row>;

	}
}

const image_style = {
	backgroundImage: "url(\"/Images/react_qyu.svg\")",
	backgroundSize: "contain"
}

export class Intro extends Component
{
	element = React.createRef<Page>();

	unlisten_history: any;

	render()
	{
		return <Page
			ref={this.element}
			url="/"
			dont_unload={true}
			className="intro-page"
			container_style={{ width: '100%' }}//von Web Apps zB mit React, Handy Apps mit React Native oder Cordova, CMS Erweiterungen in Contao, sowie Electron 
			description={{ en: "Freelance programmer. Web apps in React, Mobile Apps in React Native, Cordova. CMS Extensions ( Contao, Wordpress, Joomla, Shopware ). Electron / NodeJS.", de: "Freelance Programmierer. Web Apps in React, Handy Apps in React Native, Cordova. CMS Erweiterungen ( Contao, Wordpress, Joomla, Shopware ). Electron / NodeJS." }}
		>
			<BackgroundAnimatedWater paused={() => history.location.pathname != "/"} />


			<Column className="web_intro" style={{ position: 'absolute', top: 0 }}>
				<IntroCard scroll_element={this.element} />
				{/* <div style={{ zIndex: 1 }}>
					<a href="/About" className={"link-row"} onClick={(e: any) => { return history.go_to('/About' + history.location.search, "left", e) }}>Sven Hollesen</a>
				</div>
				<a href="#content" className={"link-row"} onClick={(e:any) => {e.preventDefault(); find_closest_scrollable(this.element.current!.container.current!)!.scrollTo({ top: window.innerHeight - 20, behavior: 'smooth' }); return false; }}><LocalizedLabel text={{ de: "Projekte", en: "Projects" }} /></a>
				<a href="/Contact" className={"link-row"} onClick={(e: any) => { return history.go_to('/Contact' + history.location.search, "up", e) }}><LocalizedLabel text={{ de: "Kontakt", en: "Contact" }} /></a> */}
			</Column>
			<div style={{ width: "100%", display: "flex", justifyContent: 'center', position: 'absolute', bottom: '2vh', pointerEvents: 'none' }}>
				<ScrollHint style={{ pointerEvents: 'auto' }} />
			</div>

			<Column id="content" style={{ position: 'absolute', top: 'calc(100% + 28px)', left: 0, width: '100%', display: 'flex', alignItems: 'center', height: 'auto' }}>
				<BackgroundUnderwater paused={() => history.location.pathname != "/"} />
				<ImageRowHelper
					img={
						<a href="/CMS" className="clickable hoverable" onClick={(e: any) => { return history.go_to('/CMS' + history.location.search, "right", e) }}><img alt="CMS Page Link" src={"/Images/CMS/cms-tile.png"} width="100%" height="100%" /></a>

					}
					text={
						<span><LocalizedLabel text={{ de: "Ich schreibe CMS Erweiterungen.", en: "I write CMS extensions." }} /></span>
					}
				/>
				<ImageRowHelper
					reverse={true}
					img={
						<div style={{ position: 'relative', width: "100%", height: "100%" }} >
							<a href="/qyu" onClick={(e: any) => { return history.go_to('/qyu' + history.location.search, "left", e) }} className="clickable hoverable" style={{ position: 'absolute', left: "0%", width: '50%', height: '100%', }}>
								<ThemeSpecificContent
									dark={<div style={{backgroundImage: "url(\"/Images/react_qyu.svg\")", backgroundSize: "contain", height:'100%'}} title="Qyu Mobile Game Page Link"/>}
									light={<div style={{backgroundImage: "url(\"/Images/react_qyu_dark.svg\")", backgroundSize: "contain", height:'100%'}} title="Qyu Mobile Game Page Link"/>}
									style={{ height: '100%', width: '100%' }} />

							</a>
							<div style={{ position: 'absolute', left: '50%', display: 'inline-block', width: '50%', height: '100%' }}>
								<a href="/cordova" className="clickable hoverable" onClick={(e: any) => { history.go_to('/cordova' + history.location.search, "left", e) }}>
									<div style={{backgroundImage: "url(\"/Images/Metro/metro-tile.svg\")", backgroundSize: "cover", height:'50%', width:'100%'}} title="Cordova Page Link"/>
								</a>
								<a href="/unity" className="clickable hoverable" onClick={(e: any) => { history.go_to('/unity' + history.location.search, "left", e) }}>
									<div style={{ width: '100%', height: '50%' }} >
										<ThemeSpecificContent
											dark={<div style={{backgroundImage: "url(\"/Images/Unity/unity_tile.svg\")", backgroundSize: "cover", height:'100%'}} title="Unity Page Link"/>}
											light={<div style={{backgroundImage: "url(\"/Images/Unity/unity_tile_dark.svg\")", backgroundSize: "cover", height:'100%'}} title="Unity Page Link"/>}
											style={{ height: '100%', width: '100%' }} />
									</div>
								</a>
							</div>

						</div>
					}
					text={
						<span><LocalizedLabel text={{ de: "Ich erstelle hybride Handy-Apps.", en: "I create hybrid mobile apps." }} /></span>
					}
				/>
				<ImageRowHelper
					design="intro_9_slice3"
					img={
						<a href="/Productivity" className="clickable hoverable" onClick={(e: any) => { history.go_to('/Productivity' + history.location.search, "right", e) }}><div style={{backgroundImage: "url(\"/Images/Productivity/icon.svg\")", backgroundSize: "cover", height:'100%'}} title="Productivity Page Link"/></a>
					}
					text={
						<span><LocalizedLabel text={{ de: "Ich baue OS unabhängige Produktionstools.", en: "I make portable desktop tools." }} /></span>
					}
				/>
				<div style={{ position: 'sticky', width: '100%', height: 40, bottom: 0, pointerEvents: 'none' }}><ScrollHint style={{ pointerEvents: 'auto', position: 'absolute', bottom: '8px', right: '8px', zIndex: 3 }} design="arrow-up" top={0} page={this.element} /></div>
			</Column>


		</Page>
	}
}