import "./ScrollHint.scss"
import { merge_props } from '../../Helpers/ReactHelper';
import React from "react";
import CSS from "csstype"
import { Page } from "./Page";
import { iOS } from "../../Helpers/Main";

export interface ScrollHint_Props
{
	style?:CSS.Properties,
	top?:number,
	design?:"arrow-up"|"arrow-down"|"default-down",
	target?:React.RefObject<HTMLElement>,
	page?:React.RefObject<Page>,
}

function isScrollable (ele:HTMLElement) {
    // Compare the height to see if the element has scrollable content
    const hasScrollableContent = ele.scrollHeight > ele.clientHeight;

    // It's not enough because the element's `overflow-y` style can be set as
    // * `hidden`
    // * `hidden !important`
    // In those cases, the scrollbar isn't shown
    const overflowYStyle = window.getComputedStyle(ele).overflowY;
    const isOverflowHidden = overflowYStyle.indexOf('hidden') !== -1;

    return hasScrollableContent && !isOverflowHidden;
};

export function find_closest_scrollable(element : HTMLElement) : HTMLElement|undefined
{
	if(isScrollable(element))
		return element;
	if(!element.parentElement)
		return undefined;

	return find_closest_scrollable(element.parentElement); 
}

export class ScrollHint extends React.Component<ScrollHint_Props>
{
	element = React.createRef<HTMLDivElement>();

	click = () =>
	{
		
		let scrollable_parent =  "target" in this.props ? this.props.target?.current : ("page" in this.props ? this.props.page?.current?.container.current : find_closest_scrollable(this.element.current!));
		if(scrollable_parent)
		{
			let top = ('top' in this.props) ? this.props.top : scrollable_parent.clientHeight;
			console.log(top)
			if(iOS())
				$(scrollable_parent).animate({scrollTop:top},500)
			else
				if('top' in this.props)
					scrollable_parent.scrollTo({top:this.props.top,behavior:"smooth"})
				else
					scrollable_parent.scrollBy({top:scrollable_parent.clientHeight,behavior:"smooth"})
			
		}
	}

	render()
	{
		if(!this.props.design || this.props.design == "default-down")
			return  <div style={this.props.style} onClick={this.click} className="clickable">
						<div ref={this.element} className="scroll-hint" style={{marginBottom:8}}>
							<div className="scroll-hint-knob"/>
						</div>
						{/* <div style={{fontSize:12, textAlign:'center',width:'100%', opacity:0.8, fontStyle:'italic', textDecoration: 'underline'}}>
							Scroll
						</div> */}
					</div>;
		else if(this.props.design == "arrow-up")
		{
			return <div {...merge_props({ref:this.element, style:{borderRadius:24,width:48,height:48,border:'2px solid white',position:'relative', filter:"brightness(calc(100% - var(--theme)))", transition:'filter 1s'}},{style:this.props.style, onClick:this.click, className:"clickable"})}>
				<img style={{fontSize:48,position:'absolute',bottom:3,left: 5}} src="/Images/arrow_up.svg" alt="" width="80%" height="80%"/>
			</div>
		}
	}
}