import React from 'react'
import { merge_props } from '../Helpers/ReactHelper'
import { Column } from './Column'
import './Gallery.scss'
import $ from 'jquery';
import { BaseProps } from "./BaseProps";
import history from "../Helpers/History";
import { FullscreenGallery } from './FullscreenGallery';


export interface ResizablePanel_Props extends BaseProps
{
	images: string[]
}

export interface ResizablePanel_State
{
	index: number;
	fullscreen: boolean;
}

export class Gallery extends React.Component<ResizablePanel_Props, ResizablePanel_State>
{
	static id = 0;
	id = Gallery.id++;
	page = ["",""];
	container = React.createRef<Column>();

	constructor(props: ResizablePanel_Props)
	{
		super(props);
		this.state = { index: 0, fullscreen: false };
	}


	updateDimensions = () => 
	{
		let container = $("#" + (this.props.id || "gallery" + this.id));
		let children = container.find('.gallery_img');
		let max_height = 0;
		for (let i = 0; i < children.length; i++)
		{
			max_height = Math.max(max_height, children[i].clientHeight);
		}
		if (max_height != 0)
			container.css("min-height", max_height);
	};

	componentDidMount()
	{
		window.addEventListener('resize', this.updateDimensions);
		this.updateDimensions();
	}

	componentWillUnmount()
	{
		window.removeEventListener('resize', this.updateDimensions);
	}

	enter_fullscreen = () =>
	{
		// popstate == history back

		this.page = [document.title,  window.location.pathname, window.location.href];
		

		let fullscreen_gallery = FullscreenGallery.instance;
		fullscreen_gallery.show(this);
		// if (fullscreen_gallery.length <= 0)
		// {
		// 	var observer = new MutationObserver(function (mutations) 
		// 	{
		// 		let index: number = parseInt(fullscreen_gallery.attr('index') as string);

		// 		console.log('changed');
		// 		let images = $(`#fullscreen_gallery_content`).children();
		// 		for (let i = 0; i < images.length; i++)
		// 		{
		// 			let image = images[i];
		// 			$(image).css("transform", `translateX(${(i - index) * 100}%)`)
		// 		}

		// 		let prev_button = $('#gallery_prev_button');
		// 		let next_button = $('#gallery_next_button');

		// 		index <= 0 ? prev_button.hide() : prev_button.show();
		// 		index >= images.length - 1 ? next_button.hide() : next_button.show();
				
		// 	});
		// 	observer.observe(fullscreen_gallery[0], {
		// 		attributes: true,
		// 		attributeFilter: ['index']
		// 	});
		// }

		//console.log("ATTR",index)
		
	}



	render() 
	{
		let { fullscreen } = this.state;
		return <Column ref={this.container}  {...merge_props({ id: "gallery" + this.id, className: "gallery_container", style: { position: 'relative', overflow: 'hidden', justifyContent: 'center', width: '100%' } }, this.props)}>
			{this.props.images?.map((v, i) =>
			{
				return <img onLoad={this.updateDimensions} key={v} className="gallery_img" src={v} loading={"lazy"} alt="" style={{ transform: `translateX(${(i - this.state.index) * 100}%)` }} />
			})}
			<div onClick={() => { this.setState({ index: this.state.index - 1 }) }} className="gallery_nav_button" style={{ left: 0, ...(this.state.index <= 0 ? { display: 'none' } : {}) }}>{"<"}</div>
			<div onClick={() => { this.setState({ index: this.state.index + 1 }) }} className="gallery_nav_button" style={{ right: 0, ...(this.state.index >= this.props.images.length - 1 ? { display: 'none' } : {}) }}>{">"}</div>
			<div onClick={this.enter_fullscreen} className="gallery_nav_button" style={{position:'absolute', right: 0,top:0,padding:0, fontSize:40}}>[&nbsp;&nbsp;]</div>
			
		</Column>
	}

}