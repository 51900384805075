import React from 'react';
import { Page, PageProps } from '../Page';
import { Column } from '../../Column';
import { Row } from '../../Row';
import { LocalizedLabel } from '../LocalizedLabel';
import { AntiCrawlerText } from '../../AntiCrawlerText';
import { BackgroundSky } from '../BackgroundSky';
import history from "../../../Helpers/History";
import "./Contact.scss";

export class Contact extends React.Component
{
	unlisten_history:any;


	componentDidMount()
	{
		this.unlisten_history = history.listen((a) => 
		{
			this.forceUpdate();
		})
	}

	componentWillUnmount()
	{
		this.unlisten_history();
	}

	render()
	{
		return <Page 
					url="/Contact" 
					className="ContactPage" 
					dont_unload={true} 
					title={{en:"Sven Hollesen - Kontakt",de:"Sven Hollesen - Contact"}}
					description={{de:"Mein Name ist Sven Hollesen, und hier finden sie die Kontaktdaten meines Unternehmens.",en:"My name is Sven Hollesen, and here you'll find the contact data of my business."}}
					>
				<BackgroundSky style={{left:'0'}} paused={()=>history.location.pathname != "/Contact"}/>
			<Column className="page_content" style={{ color: "var(--c_d)" }}>
				<Row style={{ justifyContent: 'center' }}>
					<h1 style={{ textAlign: "center" }}><LocalizedLabel text={{de:"Kontaktieren Sie mich einfach direkt per Email.",en:"Just contact me via mail."}}/></h1>
				</Row>
				<Column style={{ justifyContent: 'center' }}>
					<Row style={{ width: "100%", justifyContent: "center" }}>
						<h2 style={{textAlign:'center'}}>
							<AntiCrawlerText>sven.hollesen@gmail.com</AntiCrawlerText>
						</h2>
					</Row>
					<Row>
						<span style={{color:'var(--panel)',fontSize:"70%", textAlign:'center',width:'100%',marginTop:-24,userSelect:'none'}}>
							<LocalizedLabel text={{
								de:<span>Falls Sie TTS verwenden, und es 0@0 vor oder nach solchen Feldern liest, bitte ignorieren.<br/>Dies ist Teil eines Spambotschutzes, und sollte nicht vorgelesen werden.</span>,
								en:<span>This email field uses spam-bot-protection.<br/>If you're using TTS and it reads 0@0 anywhere on this page, please ignore it.</span>}}/>
						</span>
					</Row>
					<Column style={{background:"var(--c_1)", alignItems:'center', paddingBottom:'16px', marginTop:'32px', opacity:0.5}}>
						<h3>
						<LocalizedLabel
						text={{
							de:"Name / Name des Unternehmens",
							en:"Name / Name of Company",
						}}/>
						</h3>
						<span>
							Sven Hollesen
						</span>
						<h3>
							<LocalizedLabel
							text={{
								de:"Adresse",
								en:"Address",
							}}/>
							
						</h3>
						<span>
						<span style={{display:"inline-block"}}><AntiCrawlerText >Fargauweg 24, 24148 Kiel, Germany</AntiCrawlerText></span>
						</span>
						<h3>
							<LocalizedLabel
							text={{
								de:"Telefon (Nur Notfälle)",
								en:"Telephone (Emergencies only)",
							}}/>
							
						</h3>
						<span>
							<AntiCrawlerText>0152 09136113</AntiCrawlerText>
						</span>
						<h3>
							<LocalizedLabel
							text={{
								de:"Umsatzsteuernummer",
								en:"Tax ID",
							}}/>
						</h3>
						<span>
							<AntiCrawlerText>DE316160653</AntiCrawlerText>
						</span>
					</Column>
				</Column>
			</Column>
		</Page>;
	}
}