import React, { ReactElement } from "react";
import { Data } from "../../Helpers/Main";
import { merge_props } from "../../Helpers/ReactHelper";

export interface ThemeSpecificContentParams
{
	dark:ReactElement,
	light:ReactElement,
	[k:string]:any,
}

export class ThemeSpecificContent extends React.Component<ThemeSpecificContentParams>
{
	uid = Data.get_uid();

	componentDidMount()
	{
		Data.add_hook('theme',this.uid,()=>this.forceUpdate())
	}
  
	componentWillUnmount()
	{
	  	Data.remove_hook('theme',this.uid)
	}

	render()
	{
		let theme = Number.parseFloat(getComputedStyle(document.body).getPropertyValue('--theme'))/100;
		return <div {...merge_props({style:{position:'relative'}},this.props)}>
			<div style={{position:'absolute',top:0,left:0,width:'100%',height:'100%'}}>{this.props.light}</div>
			<div style={{opacity:1-theme,position:'absolute',top:0,left:0,width:'100%',height:'100%',transition:'opacity 1s'}}>{this.props.dark}</div>
		</div>
	}
}