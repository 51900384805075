import { Row } from "../Row";
import "./LanguageSwap.scss";
import sun from "../../Assets/Images/sun.svg"
import moon from "../../Assets/Images/moon.svg"
import { Component } from "react";
import { Data } from "../../Helpers/Main";
import $ from 'jquery';
import history from '../../Helpers/History';
import { CookieHelper } from "../../Helpers/CookieHelper";

let set_css_var = function (name,value)
{
	document.documentElement.style.removeProperty(name);
					// update the element. Note: We are doing this on <html>, but varaibles can be scoped to ANY element
	document.documentElement.style.setProperty(name, value);
};


let set_de = function ()
{
	set_css_var('--text_opacity',0);
	setTimeout(()=>{
		Data.set('language', 'de');
		set_css_var('--text_opacity',1);
	},1300)
	$(`html`).attr("lang","de");
	CookieHelper.set_cookie('language','de');
};

let set_en = function ()
{
	
	set_css_var('--text_opacity',0);
	setTimeout(()=>{
		Data.set('language', 'en');
		set_css_var('--text_opacity',1);
	},1300);
	$(`html`).attr("lang","en");
	CookieHelper.set_cookie('language','en');
};

export class LanguageSwap extends Component
{

	constructor(props)
	{
		super(props);
		this.state = {};
		var language = CookieHelper.get_cookie('language') || window.navigator.userLanguage || window.navigator.language;
		Data.set("language",language == "de" ? "de" : "en");
	}

	componentDidMount()
	{
		
		Data.add_hook('language', this.uid, () => { this.setState({}) })
	}

	componentWillUnmount()
	{
		Data.remove_hook('language', this.uid)
	}

	render()
	{
		var language = Data.get('language', 'en');
		return <Row className="language_swap" style={{justifyContent:'left'}}>
				<span onClick={set_en} className={"" + (language == "en" ? "active":"")} style={{ height: '20px', marginRight:2 }}>EN</span>
				|
				<span onClick={set_de} className={"" + (language == "de" ? "active":"")} style={{ height: '20px', marginLeft:2 }}>DE</span>
			
		</Row>
	}
}