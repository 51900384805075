import React,{Component} from 'react'
import './ResponsiveRowColumn.scss'
import {merge_props} from '../Helpers/ReactHelper';


/**
 * This is a row by default, unless the resolution becomes too low, which will cause it to switch to column mode.
 */
export class ResponsiveRowColumn extends Component
{
	ref = React.createRef();
	
  render() 
  {
    

    var result = <div ref={this.ref} {...merge_props({className:"responsive-row-column"},this.props)}>{this.props.children}</div>;

    

    return result;
  }
}