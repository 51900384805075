import React, { Component } from 'react';
import {merge_props} from '../Helpers/ReactHelper';
import { BaseProps } from './BaseProps';
import './Column.scss';


export interface Column_Props extends BaseProps
{

}

export class Column extends Component<Column_Props>
{
  ref = React.createRef();

  render()
  {


    var result = <div ref={this.ref} {...merge_props({ className: "column" }, this.props)}>{this.props.children}</div>;



    return result;
  }
}
