import { createBrowserHistory, History } from 'history';
import { Data } from "../Helpers/Main";

export type Direction = "up"|"right"|"left"|"down";
export const transition_inv = {
	up:"down",
	down:"up",
	left:"right",
	right:"left",
	undefined:"left"
}

let history = createBrowserHistory() as (History & {go_to:(path:string, mode : Direction, e:Event)=>false, mode : Direction, go_back:(mode : Direction|undefined)=>void, transitions:Direction[]});
history.transitions = [];
export default history;

let prev_location = window.location.pathname;
console.log("{ATHNAME IS " + prev_location)

history.listen((a) => 
{
	console.log(a, history.action);

	if(history.action == "POP")
	{
		let transition = history.transitions.pop()!;
		history.mode = transition_inv[transition] as Direction;
		console.log("Popped ", transition);
		//if((a.state as any)?.transition == undefined)
		//{
		//}
	}
	else
	{
		let transition = (a.state as any)?.transition || "left";
		history.transitions.push(transition);
		history.mode = transition;
	}
	Data.set("last_transition",Date.now());
	prev_location = a.pathname;

	// if (a.pathname == prev_location)
	// {
		
	// 	console.log("Set mode to ", a);
	// 	//console.log(a,history.location)
		
		
	// }
	// else
	// console.log(a.pathname + " vs " + prev_location)
})

history.go_to = (path:string, mode : Direction = "right",e:Event) =>
{
	e.preventDefault();
	//history.mode = mode;
	//history.transitions.push(mode);
	history.push(path,{transition:mode,prev:window.location.pathname});
	return false;
}


history.go_back = (mode:Direction|undefined) =>{
	console.log(history.action);
	if(history.length > 0)
	{
		console.log(history);
		//history.go(-1);
		history.goBack();
	}
	else
	{
		history.mode = "left";
		history.replace('/');
	}
}