import { Component } from "react";
import "./Footer.scss";
import { merge_props } from '../../Helpers/ReactHelper';
import arrow from '../../Assets/Images/back-arrow.svg'
import history from '../../Helpers/History';
import $ from 'jquery';
import './BackgroundAnimatedWater.scss'
import React from "react";
import { my_perlin, my_perlin2, my_perlin_2d, my_random, my_random2, perlin } from "../../Helpers/Perlin";
import { AnimatedBackground, AnimatedBackgroundState, Color, ease_in_out, interpolate_colors, rgba } from "./AnimatedBackground";
import SimplexNoise from 'simplex-noise';
import { mobile } from "../../Helpers/Main";


export class BackgroundSky extends AnimatedBackground
{
	static update_rate = .25;

	_update_id: any = -1;
	canvas = React.createRef<HTMLCanvasElement>();

	stars?: Uint32Array;
	

	noise(ctx:CanvasRenderingContext2D, width:number=10, height:number = 10, opacity : number = 0.3)
	{
		const real_width : number = ctx.canvas.width;
		const real_height : number = ctx.canvas.height;
		const iData = ctx.getImageData(0,0,real_width,real_height)//ctx.createImageData(width, height);

		this._noise(width,height,iData,opacity);

		ctx.putImageData(iData, 0, 0);
	}

	_noise(width:number=10, height:number = 10, iData : ImageData, opacity : number = 1) 
	{
		const iWidth = iData.width;
		const iHeight = iData.height;
		const buffer32 : Uint32Array = new Uint32Array(iData.data.buffer);
		const buffer_small : Float32Array = new Float32Array(width*height);

		const width_aspect = iWidth/width;
		const height_aspect = iHeight/height;
		
		for(let i = 0; i < buffer_small.length; i++)
		{
			buffer_small[i] = Math.random();
		}

		for(let y = 0; y < iHeight; y++)
		{
			let small_y = Math.floor(y/height_aspect);
			//print(small_x)
			
			for(let x = 0; x < iWidth; x++)
			{
				let i = y * iWidth + x;
				let small_x = Math.floor(x/width_aspect);
				let small_i =  small_y * Math.floor(width) + small_x;
				let rand = opacity * buffer_small[small_i] + (1-opacity);
				//console.log("Small X,Y", small_x, Math.floor(y/height_aspect));

				let r = (buffer32[i] >> 0) & 255 ;
				let g = (buffer32[i] >> 8) & 255 ;
				let b = (buffer32[i] >> 16) & 255 ;
				let a = (buffer32[i] >> 24) & 255 ;

				//let r = (buffer32[i] & 0x00ffffff) | ((buffer32[i] * buffer_small[small_i]) & 0xff000000);
				buffer32[i] = (((rand * a) & 255) << 24) + (r << 16) + (g << 8) + (b << 0);
			}
		}

	}

	_multiply(width:number,height:number,iData:ImageData,data:Uint32Array,amount : number = 1)
	{
		const iWidth = iData.width;
		const iHeight = iData.height;
		const buffer32 : Uint32Array = new Uint32Array(iData.data.buffer);

		const width_aspect = iWidth/width;
		const height_aspect = iHeight/height;

		for(let y = 0; y < iHeight; y++)
		{
			let small_y = Math.floor(y/height_aspect);
			//print(small_x)
			
			for(let x = 0; x < iWidth; x++)
			{
				let i = y * iWidth + x;
				let small_x = Math.floor(x/width_aspect);
				let small_i =  Math.floor(small_y * width) + small_x;
				//console.log("Small X,Y", small_x, Math.floor(y/height_aspect));
				let r = (buffer32[i] >> 0) & 255 ;
				let g = (buffer32[i] >> 8) & 255 ;
				let b = (buffer32[i] >> 16) & 255 ;
				let a = (buffer32[i] >> 24) & 255 ;
				let r2 = (data[small_i] >> 0) & 255 ;
				let g2 = (data[small_i] >> 8) & 255 ;
				let b2 = (data[small_i] >> 16) & 255 ;
				let a2 = (data[small_i] >> 24) & 255 ;
				r = Math.min(255, r2 * r * amount >> 8);
				g = Math.min(255, g2 * g * amount >> 8);
				b = Math.min(255, b2 * b * amount >> 8);
				a = Math.min(255, a2 * a * amount >> 8);
				buffer32[i] = (a << 24) + (r << 16) + (g << 8) + (b << 0);
			}
		}
	}

	init(canvas:HTMLCanvasElement)
	{
		super.init(canvas);
		const simplex = new SimplexNoise();

		this.stars = new Uint32Array(this.width*this.height);

		const desat = 0.6;

		for (let y = 0; y < this.height; y++)
		{
			for (let x = 0; x < this.width; x++)
			{
				let r = (simplex.noise2D(x+547,y)       + 1 ) * 255 / 2
				let g = (simplex.noise2D(x+547830,y)    + 1 ) * 255 / 2;
				let b = (simplex.noise2D(x+123435897,y) + 1 ) * 255 / 2;
				let a = Math.random() * 255;
				let median = ( r + g + b ) / 3;
				r = Math.min(r * (1-desat) + desat * median,255);
				g = Math.min(g * (1-desat) + desat * median,255);
				b = Math.min(b * (1-desat) + desat * median,255);
				a = Math.max(Math.min(Math.pow(a,4) * (1 - y / this.height) >> 24,255),0) ;
				this.stars[y * this.width + x] = (a << 24) + (r<<16) + (g<<8) + b;
				// a << 24 + 
				// r << 16 + 
				// g <<  8 + 
				// b;
				;
			}
		}
		console.log(this.stars)
	}

	// test absolute vs relative
	update_data()
	{
		//if(mobile()) // contact page takes up full screen anyawys.
		//	return;
		if(!this.state.focused)
			return;
		const speed = 0.25;

		let theme = Number.parseFloat(getComputedStyle(document.body).getPropertyValue('--theme'));
		var canvas = this.canvas.current!;
		if(canvas == undefined)
			return;
		var FONT_SIZE = this.get_font_size(canvas);


		let update = this.state.char_height === 0;

		if (update)
		{
			this.init(canvas);
		}



		// if (this.state.intro_animation> 0.5)
		// 	return;

		var ctx = canvas.getContext("2d")!;

		

		ctx.font = (theme >= 50 ? 'bold ' : '') + FONT_SIZE + "px monospace";
		let colors = AnimatedBackground.get_colors(theme/100.0);
		let colors_string = Object.keys(colors).map(v => rgba(colors[v]));

		ctx.fillStyle = colors_string[0];

		let empty_line = "*".repeat(Math.ceil(this.width))
		let empty_line_air = "#".repeat(Math.ceil(this.width))

		let intro_animation = Math.max(Math.min(this.state.time / 3, 1), 0);

		ctx.clearRect(0, 0, canvas.width, canvas.height);

		
		for (let y = 0; y < this.height; y++)
		{
			let star_color: Color = [...colors.stars];
			let sky = intro_animation * (1-Math.pow((y / this.height),1));
			star_color[3] = Math.floor(star_color[3] * sky);
			

			let start_color_string = rgba(star_color);

			ctx.fillStyle = start_color_string;
			ctx.fillText(empty_line, 0, y * FONT_SIZE);
		}

		
		//ctx.fillStyle = "blue";
		//ctx.fillRect(0,0,canvas.width,canvas.height)

		var iData = ctx.getImageData(0,0,canvas.width,canvas.height);
		this._multiply(this.real_width,this.height,iData,this.stars!,2)
		this._noise(this.real_width,this.height,iData,0.35);

		

		//console.log(my_perlin(16));
		//this._multiply(this.width,this.height,iData,my_random2(iData.width,iData.height),1);

		ctx.putImageData(iData, 0, 0);

		for (let y = 0; y <= this.height; y++)
		{
			let star_color: Color = [...colors.sky];
			let sky = intro_animation * Math.pow((y / this.height),8);
			star_color[3] = Math.floor(star_color[3] * sky);
			

			let start_color_string = rgba(star_color);

			ctx.fillStyle = start_color_string;
			ctx.fillText(empty_line_air, 0, y * FONT_SIZE);
			



			// Batch all characters in a row that are of the same color to save performance
			// let batch : string[] = [];
			// let batch_color = "";
			// let batch_start = 0;

			// ctx.fillStyle = batch_color;
			// ctx.fillText(batch.join(''), batch_start * this.state.char_width, y * this.state.char_height);
		}


		this.state.theme = theme;
		this.state.intro_animation = intro_animation;

		//this.noise(ctx,width,height);
	}

	render()
	{
		return <canvas ref={this.canvas} className="background-animated-water-canvas" style={{height:'100%', position:'absolute', top:0,...this.props.style}}></canvas>
	}
}
