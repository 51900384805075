// TODO Lock :
// Lock mechanism, auto expires after inactivity

// TODO : User settings
// eg "Trust implicitly" auto accepts all packages from user in an effort to save performance 

export class Data
{
	
    _data = {
        _active_fragment:"root",
        fragments: // Each node in the tree is an independent fragment. This way no data is lost if parents get deleted or moved
        {
            root:
            {
                label:"Root",
                text:"",
                children:{
                    "childA":undefined,
                    "childB":'childA', // ChildB sorted after childA (This produces minimal synchronization conflicts)
				},
                parent:undefined,
				_selected:true,
            },
            childA:
            {
                label:"Child A",
                text:"",
                children:{},
				parent:"root",
            },
            childB:
            {
                label:"Child B",
                text:"",
                children:{"childC":undefined},
				parent:"root",
            },
            childC:
            {
                label:"Child C",
                text:"",
                children:{},
				parent:"childB",
            },
        },
        _uid : 0,
    	users :
        {
		},
		_pending_users : 
		{
		},
		_new_user_mode:"manual",
		_default_user_group:"guest",
		_peer_id:undefined
    }; 


    _hooks = {}; // Hooks that get called via _changed batches (Asynchronous)
    _insta_hooks = {}; // Hooks that get called the moment the path changes (Synchronous). Beware infinite loops.
	_changed = undefined; // Any path that recently changed. This will periodically get processed, which will result in hooks being called

	
	uid = Math.floor(Math.random()*Number.MAX_SAFE_INTEGER).toString(36); // [0-9a-z]
	_uid = 0;

	constructor(data = this._data)
	{
		this._data = data;

		setInterval(this.onTimerTick.bind(this), 100); // 10 frames per second
	}

	onTimerTick() {
		if(this._changed == undefined)
			return; // No changes
		console.log("Processing changed",this._changed)
		Data._process_changed(this._changed,this._hooks);
		// Then clean _changed = undefined
		this._changed = undefined;

	}

    get_uid()
    {
        return this.uid + "_" + this._uid++;
	}
	
	serialize_data(data)
	{
		JSON.stringify(data);
	}

	deserialize_data(data)
	{
		JSON.parse(data)
	}
	
	/**
	 * Split a string path into an array of steps
	 * @param {string} path 
	 * @returns {Array.<string>}
	 */
	static split_path(path)
	{
		if (path == "")
			return [];
		var current_split = "";
		var splits = [];

		var is_escape = false;
		var is_in_double_quotes = false;
		var is_in_single_quotes = false;
		for (let i = 0; i < path.length; i++) 
		{
			const c = path[i];
			
			if (is_escape)
			{
				current_split += c;
				is_escape = false;
				continue;
			}

			switch (c)
			{
				case "/":
					if(!(is_in_single_quotes || is_in_double_quotes))
					{	
						splits.push(current_split);
						current_split = "";
						break;
					}
					else
					{
						current_split += c;
						break;
					}
				case '\\':
					is_escape = !is_escape;
					break;
				case '"':
					is_in_double_quotes = !is_in_double_quotes;
					break;
				case "'":
					is_in_single_quotes = !is_in_single_quotes;
					break;
				default:
					current_split += c;
			}
		}
		splits.push(current_split);

		return splits;
	}

    /**
     * Get Data by path, return default if path holds no data
     * @param {string} path 
     * @param {*} _default 
     */
    get(path,_default=null)
    {
        var data = Data._get_by_path(path,this._data,_default);

        return data;
	}
	
	/**
     * Check if the data path exists
     * @param {string} path 
     * @returns {boolean}
     */
    has(path)
    {
        var data = Data._has_by_path(path,this._data);

        return data;
    }

	/**
	 * Set or create data at path
	 * @param {string} path 
	 * @param {*} value 
	 */
    set(path, value, shared = true)
    {
        Data._set_by_path(path,this._data,value);
		this._update_set_hooks(path);
		if(shared)
		{
			this._active_packet = Data.add_path_to_change_object(path,this._active_packet);
			console.log('Active packet became ', this._active_packet)
		}
	}
	
	/**
	 * Remove the value at the path. Unlike set(undefined), this will remove the key as well.
	 * @param {string} path 
	 */
	remove(path, shared = true)
	{
		Data._remove_by_path(path,this._data);
		this._update_set_hooks(path);
		if(shared)
			this._active_packet = Data.add_path_to_change_object(path,this._active_packet);
	}

	/**
	 * Turn a change Object into a matching cloned representation of _data
	 * @param {object} change_object 
	 */
	static resolve_change_object(change_object,data=this._data)
	{
		if(Object.keys(change_object).length == 0)
		{
			var result = Data.deep_clone(data,{});
			if(data == undefined)
				return undefined;
			if(typeof(data) == 'object')
				result["%"] = 1; //Full object replacement marker to separate field setter from object replacer
			return result;
		}
		else
		{
			var result = {};
			for(const k in change_object)
			{
				console.log(k ,'in',Data.deep_clone(data,{}));
				if(!(k in data))
					result[k] = {"%":2}; // Peerjs mangles undefined and null together, which is why remove needs to be a special marker object
				else
				{
					if(data[k] == undefined)
						result[k] = undefined;
					else
						result[k] = this.resolve_change_object(change_object[k],data[k]);
				}
			}
			return result;
		}
	}

	/**
	 * Remove all private fields from the object (deep)
	 * @param {Object} data 
	 */
	static strip_privates(data)
	{
		for(const k in data)
		{
			if(k.startsWith('_'))
				delete data[k];
			else if(typeof(data[k]) == "object")
				this.strip_privates(data[k]);
		}
	}

	static add_path_to_change_object(path,data)
	{
		var splits = typeof(path) == "string" ? Data.split_path(path) : path ;

		

		if(data != undefined && Object.keys(data).length == 0)
		{
			return data; // if an empty leaf exists, parent already changed this frame and child changes become irrelevant
		}

		if(data == undefined)
		{
			data = {};
		}

		if (splits.length == 0)
		{
			return data = {};
		}

		var orig_data = data;

        for (let i = 0; i < splits.length-1; i++) 
        {
            var split = splits[i];
			if(! (split in data))
			{
                data = data[split]||(data[split] = {});
			}
			else
			{
				if(Object.keys(data[split]).length == 0)
				{
					return orig_data; // if an empty leaf exists, parent already changed this frame and child changes become irrelevant
				
				}
				data = data[split]||(data[split]={});
			}
		}
		

        var split = splits[splits.length-1];
        if(! (split in data))
            data = data[split] = {}
        else
			data = data[split];
			

		return orig_data;
	}

    _update_set_hooks(path)
    {
        

		
		
		this._changed = Data.add_path_to_change_object(path,this._changed);
		

        // Trigger insta hooks
        Data._trigger_hooks(path,this._insta_hooks);

        return;

        //this._get_by_path_or_create_stop_at_empty(path,this._changed); // create path to changed if it doesn't exist. All nodes in the _changed tree will trigger hooks eventually (batched)

        
    }

    static _trigger_hooks(path,hooks)
    {

        var splits = typeof(path) == "string" ? Data.split_path(path) : path ;      

        for (let i = 0; i < splits.length; i++) 
        {
            if ('__hooks__' in hooks)
            {
                var __hooks__ = hooks["__hooks__"];
                for(var key in __hooks__)
                {
                    var hook = __hooks__[key];
                    hook(path);
                }
            }
            var split = splits[i];
            if(! (split in hooks))
                return;
            else
                hooks = hooks[split];
        }

        if ('__hooks__' in hooks)
        {
            var __hooks__ = hooks["__hooks__"];
            for(var key in __hooks__)
            {
                var hook = __hooks__[key];
                hook(path);
            }
        }
    }

    /**
     * 
     * @param {string} path 
     * @param {object} data 
     * @param {*} _default 
     * @returns {*}
     */
    static _get_by_path(path,data,_default=null)
    {
        var splits = typeof(path) == "string" ? this.split_path(path) : path ;

        for (let i = 0; i < splits.length; i++) 
        {
            var split = splits[i];
            if(! (split in data))
                return _default;
            else
                data = data[split];
        }

        

        return data;
	}
	

	/**
     * 
     * @param {string} path 
     * @param {object} data 
     * @param {*} _default 
     * @returns {*}
     */
    static _has_by_path(path,data)
    {
        var splits = typeof(path) == "string" ? this.split_path(path) : path ;

        for (let i = 0; i < splits.length; i++) 
        {
            var split = splits[i];
            if(! (split in data))
                return false;
            else
                data = data[split];
        }

        

        return true;
    }

    static _get_by_path_or_create(path,data,_default={})
    {
		var splits = typeof(path) == "string" ? this.split_path(path) : path ;
		
		if (splits.length == 0)
			return data || _default;
        

        for (let i = 0; i < splits.length-1; i++) 
        {
            var split = splits[i];
            if(! (split in data))
                data = data[split] = {}
            else
                data = data[split];
        }

        var split = splits[splits.length-1];
        if(! (split in data))
            data = data[split] = _default
        else
            data = data[split];


        

        return data;
    }

    static _set_by_path(path,data,value)
    {
		var splits = typeof(path) == "string" ? this.split_path(path) : path ;
		

		if(splits.length == 0)
		{
			this._data = value;
			return;
		}

        for (let i = 0; i < splits.length-1; i++) 
        {
            var split = splits[i];
            if(! (split in data))
                data[split] = {}
            
            data = data[split];
        }

        data[splits[splits.length-1]] = value;
	}
	
	static _remove_by_path(path,data)
    {
        var splits = typeof(path) == "string" ? this.split_path(path) : path ;

        for (let i = 0; i < splits.length-1; i++) 
        {
            var split = splits[i];
            if(! (split in data))
                data[split] = {}
            
            data = data[split];
        }

        delete data[splits[splits.length-1]];
    }


    /**
     * Add a function hook via a uid to the path. Anytime the variable at the path changes, the hook gets queued as changed, which will cause its hooks to be notified of the change eventually (But at most a fraction of a second later)
     * @param {string} path 
     * @param {strin} uid 
     * @param {func} hook 
     */
    add_hook(path,uid,hook)
    {
		if(Data._has_by_path(path+'/__hooks__/'+uid,this._hooks))
			console.error('hook exists already',path,uid);
        Data._set_by_path(path+'/__hooks__/'+uid, this._hooks, hook);
    }

    remove_hook(path,uid)
    {
		var hooks = Data._get_by_path(path+'/__hooks__',this._hooks);
		if(hooks == undefined || !(uid in hooks) )
		{
			console.error("Tried to remove non existant hook ", uid, 'from', path)
		}
        delete hooks[uid];
        if (Object.keys(hooks).length == 0)
            delete Data._get_by_path(path,this._hooks)["__hooks__"];
    }

    /**
     * Add a function hook via a uid to the path. Anytime the variable at the path changes, the hook gets called
     * @param {string} path 
     * @param {strin} uid 
     * @param {func} hook 
     */
    add_insta_hook(path,uid,hook)
    {
        Data._set_by_path(path+'/__hooks__/'+uid, this._insta_hooks, hook);
    }

    remove_insta_hook(path,uid)
    {
        var hooks = Data._get_by_path(path+'/__hooks__',this._insta_hooks);
        delete hooks[uid];
        if (Object.keys(hooks).length == 0)
            delete Data._get_by_path(path,this._insta_hooks)["__hooks__"];
	}
	
	/**
	 * Take an object of type key:string=>value:string where value specifies the previous element and turn it into an array
	 * It is assumed no conflicts exist
	 * @param {Object} obj 
	 */
	static sorted_object_to_array(obj)
	{
		var lookup = {};

		for (const k in obj) 
		{
			var prev = obj[k];
			
			lookup[prev] = k;
		}

		var element = lookup[undefined] || lookup[null]; // no other element leads to this one <==> start element
		var result = [];

		while(element != undefined) 
		{
			result.push(element);
			element = lookup[element];
		}

		return result;
	}

	static array_to_sorted_object(arr)
	{	
		var result = {};
		var element = undefined;
		for (let i = 0; i < arr.length; i++) 
		{
			result[arr[i]] = element; // assign previous element to it
			element = arr[i];
		}

		return result;
	}

	/**
	 * Clones obj into result. If result is not empty, it will merge and obj will override where necessary.
	 * @param {object} obj 
	 * @param {object?} result 
	 */
	static deep_clone(obj,result)
	{

		if(obj == undefined || obj == null)
			return obj;

		if (typeof(obj) == "object")
		{
			if(Array.isArray(obj))
			{
				result = []; // override, used to be just objects, but now arrays are needed as well
			}
			for(const k in obj)
			{
				result[k] = this.deep_clone(obj[k],result[k]||{});
			}
		}
		else
		{
			result = obj; // values like string, number etc are essentially passed by value
		}

		return result;
	}


	static _process_changed(changed,hooks,path="")
	{
		function call_hooks(hooks,path,deep=false)
		{
			if(deep)
			{
				for(const child_hook in hooks)
				{
					if(child_hook == "__hooks__")
						continue;
					call_hooks(hooks[child_hook],path == "" ? child_hook : path+`/${child_hook}`,true);
				}
			}

			if (!("__hooks__" in hooks))
			{
				return;
			}

			var __hooks__ = hooks["__hooks__"];
			for(var key in __hooks__)
			{
				var hook = __hooks__[key];
				hook(path);
			}
		}
		// The thing is, if a child changes, the parent changes
		// But if a parent changes, does the child change? YES! How about a full replacement? The child changes !
		// So just don't extend empty objects, and iterate all child hooks in empty objects

		var keys = Object.keys(changed);
		for(var key in keys)
		{
			key = keys[key];
			this._process_changed(changed[key],hooks[key]||{},path == "" ? key : path+`/${key}`);
		}

		var is_leaf = keys.length == 0; // leaf nodes need to call all hooks under it recursively. Passerby nodes only need to call themselves.

		call_hooks(hooks,path,is_leaf);
		
		
		
	}
}
