import "./Page.scss"
import { merge_props } from '../../Helpers/ReactHelper';
import { Footer } from "./Footer";
import React from "react";
import history from "../../Helpers/History";
import CSS from "csstype"
import { Data, iOS } from "../../Helpers/Main";
import { MetaHelper } from "../../Helpers/MetaHelper";


export interface PageProps
{
	url: string,
	title?:{de:string,en:string},
	description?:{de:string,en:string},
	container_style?: React.HTMLAttributes<HTMLDivElement>['style'],
	dont_unload?:boolean,
	style?:CSS.Properties,
	[key:string]:any
}




export class Page extends React.Component<PageProps>
{
	unlisten_history: any;
	rendered: boolean;
	loading_count = 0;
	container = React.createRef<HTMLDivElement>();
	active:boolean = false;
	override:boolean = false;

	constructor(props: PageProps)
	{
		super(props);
		this.active = props.url == window.location.pathname;
		if(this.active)
		{
			console.log("PAGE ACTIVE " + props.url);
		}
		this.rendered = this.active;
	}

	shouldComponentUpdate(nextProps:any, nextState:any) {
		return this.override ;
	 }

	 forceUpdate()
	 {
		 this.override = true;
		 super.forceUpdate();
	 }

	componentDidMount()
	{
		this.unlisten_history = history.listen((a) => 
		{
			if (a.pathname == this.props.url)
			{
				if(this.active)
					return;
				this.active = true;
				this.on_load();

				
				
			}
			else 
			{
				if(!this.active)
					return;
				
					
					this.active = false;
					this.container.current!.className = "inactive page " + (this.props.className || "");
					//this.container.current!.style.animation = "slide-" + mode + " 1s linear reverse both;"
					this.container.current!.style.animation = this.get_animation();
					console.log("INACtivating " + this.props.url,this.get_animation() );
				if (this.rendered && !this.props.dont_unload) // if unload is true, unload the page after it is no longer visible. Use this for performance heavy pages
				{
					this.on_unload();
				}
				
			}
		})
	}

	componentWillUnmount()
	{
		this.unlisten_history();
	}

	on_load()
	{
		
		this.loading_count++;
		if (!this.rendered)
		{
			this.rendered = true;
			this.forceUpdate();
		}
		else
		{
			
			this.container.current!.style.animation = this.get_animation()
		}
	}

	on_unload()
	{
		let expected_loading_count = this.loading_count; // user may switch between pages rapidly, creating many timeout contexts, of which only the last should do anything.

		setTimeout(() =>
		{
			if (this.loading_count === expected_loading_count)
			{
				this.rendered = false;
				this.forceUpdate();
			}
		}, 500);
	}

	get_animation()
	{
		// detect if back event.
		let is_back = history.action == 'POP';

		let duration = is_back && iOS() ? '0s' : '0.5s';

		let active = this.props.url == window.location.pathname;
		return "slide-" + (active ? {up:"down",down:"up",left:"right",right:"left"}[history.mode] : history.mode) + (active ? "" : "-reverse") + " " + duration + " both"
		//return "slide-" + (active ? history.mode : {up:"down",down:"up",left:"right",right:"left"}[history.mode]) + " 1s linear "+ (active ? "" : "reverse") +" both"
	}

	render()
	{
		let props = {...this.props};
		delete props["title"];
		delete props["dont_unload"];

		this.override = false;

		let active = props.url == window.location.pathname;
		
		if(active)
		{
			let language : "en"|"de" = Data.get("language","en");
			MetaHelper.set_title(this.props.title ? this.props.title[language] : (language == "de" ? "Sven Hollesen - Web und App Entwickler aus Kiel" : "Sven Hollesen - Web and App Developer from Kiel") );
			MetaHelper.set_description(this.props.description ? this.props.description[language] : (language == "de" ? "Ich schreib sowohl als Hobby als auch in meiner Freizeit Programme, Web Apps, Handy Apps und mehr." : "I create modern web apps, mobile apps, hybrid desktop programs, and more."));
		}
		
		let animation = this.get_animation()
		if (!this.rendered)
		{
			return <div ref={this.container} {...merge_props({style:{visibility:'hidden',animation}, className:  active ? "active page " : "inactive page "  }, props)}>
				<div style={{ margin: 'auto', maxWidth: '100%' }}>
				</div>
			</div>
		}
		
		return <div ref={this.container} {...merge_props({ style:{animation},className: active ? "active page " : "inactive page " }, props)}>
			<div style={{ margin: 'auto', maxWidth: '100%',...props.container_style }}>
				{props.children}
			</div>
		</div>
	}
}