import { Component } from "react";
import "./Footer.scss";
import {merge_props} from '../../Helpers/ReactHelper';
import arrow from '../../Assets/Images/back-arrow.svg'
import history from '../../Helpers/History';

export class Footer extends Component
{
	render()
	{
		return <div style={{width:'100%',height:20,color:"hotpink",position:'absolute',bottom:0,zIndex:10}}>
			<a style={{right:0,position:'absolute', background:'black',paddingLeft:4}} href="">Impressum</a>
			{this.props.children}
		</div>
	}
}
