import { Component } from "react";
import "./Footer.scss";
import { merge_props } from '../../Helpers/ReactHelper';
import arrow from '../../Assets/Images/back-arrow.svg'
import history from '../../Helpers/History';
import $ from 'jquery';
import './BackgroundAnimatedWater.scss'
import React from "react";
import { perlin } from "../../Helpers/Perlin";
import { AnimatedBackground, AnimatedBackgroundState, Color, ease_in_out, interpolate_colors, rgba } from "./AnimatedBackground";

export interface BackgroundAnimatedWaterState extends AnimatedBackgroundState
{
	data: number[][],
	char_width: number,
	char_height: number,
	focused: boolean,
	time: number,
	theme: number,
	intro_animation : number,
}

export class BackgroundAnimatedWater extends AnimatedBackground
{
	static update_rate = 0.05;
	
	state: BackgroundAnimatedWaterState;
	_update_id: any = -1;
	canvas = React.createRef<HTMLCanvasElement>();
	constructor(props: any)
	{
		super(props);


		this.state = {
			data: [],
			char_width: 0,
			char_height: 0,
			focused: true,
			time: 0,
			theme:-1,
			intro_animation:-1
		};
	}

	

	// test absolute vs relative
	update_data()
	{
		if(!this.state.focused)
			return;
			
		const speed = 0.25;

		const colors_light:[number,number,number,number][] = [[10,100,255,20],[0,0,150,100],[0,50,240,255]];
		const colors_dark:[number,number,number,number][] = [[0,204,255,17],[0,204,255,102],[0,204,255,255]];

		let theme = Number.parseFloat(getComputedStyle(document.body).getPropertyValue('--theme'));
		var canvas = this.canvas.current;
		if(canvas == undefined)
			return;
		var FONT_SIZE = this.get_font_size(canvas);


		if (this.state.char_height === 0)
		{
			this.init(canvas);
		}

	
		// if (this.state.intro_animation> 0.5)
		// 	return;
		
		var ctx = canvas.getContext("2d")!;

		let height = canvas.height! / FONT_SIZE
		let width = canvas.width! / this.state.char_width

		ctx.font = (theme >= 50 ? 'bold ' : '') + FONT_SIZE + "px monospace";

		let colors = colors_dark.map((v,i)=>interpolate_colors(v,colors_light[i],theme/100));
		let colors_string = colors.map(v=>rgba(v));

		ctx.fillStyle = colors_string[0];

		let empty_line = "#".repeat(Math.ceil(width))
		

		let wave_height_1 = (x:number)=>{
			let intro_animation = 1;
			if(this.state.time < 3)
				intro_animation = ease_in_out(Math.max(Math.min(this.state.time/2 - 0.5,1),0));
			let waves = (Math.sin(x / 10 + this.state.time * speed) + intro_animation * 2.5 - 1)*height/12
			return waves;
		}

		let wave_height_2 = (x:number)=>{
			let intro_animation = 1;
			if(this.state.time < 1)
				intro_animation = ease_in_out(Math.min(this.state.time,1));

			return intro_animation * (Math.sin(x / 11 + this.state.time * speed / 2 + 2) / 2 + 1.8)*height/13
		}

		let intro_animation = Math.max(Math.min(this.state.time/3,1),0);

		let start_y = Math.floor(this.state.theme == theme && intro_animation == this.state.intro_animation ? 3*height/4 : 0); // unless theme changes, ignore static (non wave) parts of the background

		ctx.clearRect(0, (start_y ) * canvas.height / height, canvas.width, canvas.height);

		

		for (let y = start_y + 1; y <= height + 1; y++)
		{
			let color_background : Color = [...colors[0]];
			color_background[3] = Math.floor(intro_animation * (Math.floor((height - y) * 50 / height) + 5));
			//console.log(color_background[3]);
			let color_background_string = rgba(color_background);
			if (Math.floor(3 * height / 4) >= y)
			{
				ctx.fillStyle = color_background_string;
				ctx.fillText(empty_line, 0, y * FONT_SIZE);
				continue;
			}

			// Batch all characters in a row that are of the same color to save performance
			let batch : string[] = [];
			let batch_color = "";
			let batch_start = 0;
			let current_color;

			for (let x = 0; x < width; x++)
			{
				let _wave_height_1 = wave_height_1(x);
				let _wave_height_2 = wave_height_2(x);

				current_color = colors_string[0];
				if (height - _wave_height_1 > y)
				{
					if (height - _wave_height_2 < y)
					{
						current_color = colors_string[1];
						batch.push("~");
					}
					else
					{
						current_color = color_background_string;
						//if(perlin(x/50,y/20) > 0.3)
						//	current_color = "#406070ff"
						//current_color = rgba(interpolate_colors([0,0,0,0],[255,255,255,255],Math.min(Math.max(0,perlin(x/50,y/20)-0.3)*100,1)));
						batch.push("#");
					}
				}
				else
				{
					current_color = colors_string[2];
					batch.push("~");
				}

				if (batch_color == "")
				{
					batch_start = x;
					batch_color = current_color;
				}
				else if (batch_color != current_color)
				{
					let b = batch.pop()!;
					ctx.fillStyle = batch_color;
					ctx.fillText(batch.join(''), batch_start * this.state.char_width, y * FONT_SIZE);
					batch_color = current_color;
					batch_start = x;
					
					batch.length = 1;
					batch[0] = b;
				}

				//ctx.restore();
			}
			ctx.fillStyle = batch_color;
			ctx.fillText(batch.join(''), batch_start * this.state.char_width, y * FONT_SIZE);
		}

		// if (this.props.boat)
		// {
		// 	let center_x = Math.round(width/2);
		// 	let center_y = Math.ceil(height - wave_height_1(center_x));
		// 	let angle = Math.cos(center_x / 10 + this.state.time); // [-1,1]
		// 	//console.log(angle);
		// 	// if(angle < -0.5)
		// 	// {

		// 	// }
		// 	// else if (angle > 0.5)
		// 	// {
		// 	// 	ctx.clearRect((center_x -1) * this.state.char_width, (center_y - 0) * this.state.char_height, this.state.char_width, this.state.char_height);
		// 	// 	ctx.fillText("<", (center_x - 1 ) * this.state.char_width, (center_y + 1) * this.state.char_height);

		// 	// 	ctx.clearRect((center_x ) * this.state.char_width, (center_y - 1) * this.state.char_height, this.state.char_width, this.state.char_height);
		// 	// 	ctx.fillText("x", (center_x  ) * this.state.char_width, center_y * this.state.char_height);

		// 	// 	ctx.clearRect((center_x + 1) * this.state.char_width, (center_y - 2) * this.state.char_height, this.state.char_width, this.state.char_height);
		// 	// 	ctx.fillText(">", (center_x + 1 ) * this.state.char_width, (center_y - 1) * this.state.char_height);
		// 	// }
		// 	// else
		// 	// {
				
		// 		ctx.fillStyle = "#888";

		// 		ctx.clearRect((center_x) * this.state.char_width, (center_y - 2) * this.state.char_height, this.state.char_width, this.state.char_height);
		// 	    ctx.fillText("D", (center_x  ) * this.state.char_width, (center_y - 1) * this.state.char_height);

		// 		ctx.fillStyle = "#663300";

		// 		ctx.clearRect((center_x - 1) * this.state.char_width, (center_y - 1) * this.state.char_height, this.state.char_width * 3, this.state.char_height);
		// 		ctx.fillText("<->", (center_x - 1 ) * this.state.char_width, center_y * this.state.char_height);
		// 	//}
		// }

		this.state.theme = theme;
		this.state.intro_animation = intro_animation;
	}

	render()
	{
		return <canvas ref={this.canvas} className="background-animated-water-canvas"></canvas>
	}
}
