import React from 'react';
import { Page } from '../Page';
import { Column } from '../../Column';
import { Row } from '../../Row';
import { Gallery } from '../../Gallery';

import Header from '../../../Assets/Images/feature_graphic.svg'
import { ResponsiveRowColumn } from '../../ResponsiveRowColumn';
import { LocalizedLabel } from '../LocalizedLabel';

export function ReactPage()
{
	return <Page url="/React">

		<Column className="page_content" style={{ color: "var(--c_d)", position: 'relative', marginTop: 0, overflow: 'initial', padding: 0 }}>
			<Column style={{ width: "100%", height: "100%", overflowY: 'auto', padding: 8 }}>

				{/* <img style={{ height: 150, opacity: 1, objectFit: 'cover', objectPosition: "50% 50%" }} src={"Images/Qyu/feature_graphic.svg"} loading={"lazy"} /> */}
				<Row style={{ justifyContent: 'center' }}>
					<h1>
						<LocalizedLabel text={{
							de: "Über diese Webseite",
							en: "About this website"
						}} />
					</h1>
				</Row>

				<span style={{ width: '100%', textAlign: 'center' }}>
					<LocalizedLabel text={{
						de: <span>Diese Webseite wurde mit <a href={"https://reactjs.org/"} target="_blank">React</a> entwickelt und ist in <a href={"https://www.typescriptlang.org/"} target="_blank">Typescript</a> und <a href={"https://sass-lang.com/"} target="_blank">SASS</a> geschrieben.</span>,
						en: <span>This website was made with <a href={"https://reactjs.org/"} target="_blank">React</a> in <a href={"https://www.typescriptlang.org/"} target="_blank">Typescript</a> and <a href={"https://sass-lang.com/"} target="_blank">SASS</a>.</span>
					}} />

				</span>
				<ResponsiveRowColumn style={{ alignItems: 'center', marginTop:16 }}>
					<span>
						<LocalizedLabel text={{
							de: "Zur Kompilierzeit nutze ich Babel und Webpack, sowie Linting mit ESLint. Keine fremden React Componenten (bis auf die im React Packet integrierten Grundkomponenten) wurden verwendet.",
							en: "At compiletime this App uses both Babel and Webpack and linting via ESLint. It does not use foreign React Components beyond the built-in ones."
						}} />

					</span>
				</ResponsiveRowColumn>
				<ResponsiveRowColumn style={{ alignItems: 'center' }}>
					<LocalizedLabel text={{
						de: <span>
							<h2>Was ich anbiete</h2>
						Ich entwickle gerne für Sie ähnliche Webseiten wie diese in React, oder einem Framework Ihrer Wahl (wie zb Angular oder Vue) .
						Falls Sie keinen eigenen Designer haben, und das Design der Seite neu entwickelt werden soll, arbeite ich in Partnerschaft mit <a href={"https://www.mediendiele.de/"} target="_blank">Mediendiele</a>, einer lokalen Mediendesignfirma, die komplizierte Graphiken für mich entwickeln. (Mediendiele war nicht an der Entwicklung dieser Seite beteiligt) <br /><br />
						Ich biete weiterhin auch php basierte Webseiten an, zB mit Laravel.
					</span>,
						en: <span>
							<h2>What I offer</h2>
						I offer React based websites and webapps as a service.<br />
						If your project requires new graphical designs and you don't have a designer you trust already, I work in partnership with <a href={"https://www.mediendiele.de/"} target="_blank">Mediendiele</a>, a local media design company, who will design complex graphical templates instead of me.<br /><br />
						Just because this website was created in React does not mean I don't offer other frameworks. I've experience with PHP/Laravel and many JS/Nodejs based frameworks.
					</span>
					}} />

				</ResponsiveRowColumn>

				<div style={{ minHeight: 12, width: 10 }} />

			</Column>
		</Column>


	</Page>;
}
