import React from 'react';
import { Page } from '../Page';
import { Column } from '../../Column';
import { Row } from '../../Row';
import "./About.scss";
import { DynamicImage } from '../DynamicImage';
import { LocalizedLabel } from '../LocalizedLabel';
import history from '../../../Helpers/History';

export function About()
{
	return <Page url="/About" className="about" container_style={{maxWidth:800}}>
		<Column className="page_content" style={{ color: "var(--c_d)" }}>
			<Row style={{ justifyContent: 'center' }}>
				<h1>Hallo</h1>
			</Row>
			<Row className="about1" style={{ width: "100%", justifyContent: "center", alignItems: 'stretch' }}>
				<Column style={{flex:1,minWidth:'300px',maxWidth:'600px', alignSelf:"center"}}>
					{/* <DynamicImage style={{maxWidth:'100%'}} images={["Images/portrait.jpg","Images/portrait.png"]} /> */}
					<img alt="That's me!" style={{maxWidth:'100%'}} src="Images/portrait.jpg"></img>
				</Column>
				<LocalizedLabel
					style={{ display:'flex', flexDirection:'column', padding: '8px', justifyContent: 'space-between', flex: 1, height: 'auto' }}
					text={{
					de:
					<>
						<Row className="card">
							Ich bin ein selbstständiger Software Entwickler aus Kiel.
						</Row>
						<Row className="card">
							Ich habe das Programmieren vor über 15 Jahren als Kind erlernt.
						</Row>
						<Row className="card">
							Über die Zeit habe ich mit diversen Programmiersprachen gearbeitet, von C bis Python, Java in der Uni, C# als Hobby, und viele weitere nebenbei. Ich sehe mich daher als Programmiersprachen-agnostisch, auch wenn mein Fokus natürlich auf den großen Full-Stack Sprachen (js, c#, php, python, html, css) liegt.
						</Row>
						<Row className="card">
							Ich bin seit mehreren Jahren unter meinem eigenen Namen als selbstständiges Gewerbe tätig.
						</Row>
						</>,
					en:
					<>
						<Row className="card">
							I am an independent software developer based in Kiel, Germany.
						</Row>
						<Row className="card">
							I learned programming 15 years ago while I was still a child.
						</Row>
						<Row className="card">
							I've used a variety of programming languages over the years, from C to Python, Java at university, C# as a Hobby, and many more on the side. I therefore see myself as language-agnostic, even if my focus is on the large Full-Stack languages (js, c#, php, python, html, css).
						</Row>
						<Row className="card">
							I've been self-employed under my own name for several years now.
						</Row>
					</>
				}}/>
					
			</Row>
			<Column  className="card" style={{margin:'4px', marginTop:'16px'}}>
				<LocalizedLabel
					text={{
					de:
					<>
						<Row>
							<h2 style={{textAlign:'center',width:'100%'}}>Meine professionellen Leistungen</h2>
						</Row>
						<Row>
							Ich entwickle Software, Apps, CMS Erweiterungen und Webapps auf Pauschale. Kontaktieren Sie mich dafür einfach direkt, woraufhin ich ihnen einen Kostenvorabschlag kalkuliere. Dies bedeutet für Sie, dass keine spontanen Mehrkosten entstehen, und für mich, dass der Projektumfang im Voraus klar dokumentiert ist.
						</Row>
						<Row>
							Übernahmen laufender Projekte hingegen biete ich normalerweise nur gegen Stundenlohn anhand tatsächlichen Aufwands, oder gegen kleine "Etappen-" Pauschalen an , da die unvorhersehbare Code Qualität der vorherigen Entwickler eine generelle pauschale Abschätzung in vornherein meist unmöglich macht.
						</Row>
					</>,
					en:
					<>
						<Row>
							<h2 style={{textAlign:'center',width:'100%'}}>My professional services</h2>
						</Row>
						<Row>
							I develop software, apps, CMS extensions and webapps. If you're interested, just contact me via e-mail with your project details, and I will propose a flat cost for your project.
						</Row>
						<Row>
							I'm also willing to take over maintenance or developement of existing webapps. However, I can only offer this on an hourly rate, since I cannot estimate how long a project would take without being familiar with the underlying code's quality.
						</Row>

					</>,
				}}/>
				{/* <Row style={{justifyContent:"center", padding:16}}>
					<a style={{border:'1px solid black',  background:'#eee', borderRadius:4, color:'black', width:200, display:'block', padding:'16px', fontSize:16, textAlign:'center'}} 
					href="/Prices" onClick={(e: any) => { return history.go_to('/Prices' + history.location.search, "left", e) }}>
						<LocalizedLabel
							text={{
							de:"Preise",
							en:"Prices"}}
						/>
					</a>
				</Row> */}
					
			</Column>
		</Column>
	</Page>;
}
