import React from 'react';
import { Page, PageProps } from '../Page';
import { Column } from '../../Column';
import { Row } from '../../Row';
import { LocalizedLabel } from '../LocalizedLabel';
import { AntiCrawlerText } from '../../AntiCrawlerText';
import { BackgroundSky } from '../BackgroundSky';
import history from "../../../Helpers/History";
import "./Contact.scss";

const card_style =  {className:"card",style:{marginBottom:'16px'}};

export class Prices extends React.Component
{
	unlisten_history: any;


	// componentDidMount()
	// {
	// 	this.unlisten_history = history.listen((a) => 
	// 	{
	// 		this.forceUpdate();
	// 	})
	// }

	// componentWillUnmount()
	// {
	// 	this.unlisten_history();
	// }

	render()
	{
		return <Page url="/Prices" className="PricePage" container_style={{maxWidth:1000}}>
			<Column className="page_content" style={{ color: "var(--c_d)" }}>
				<Row style={{ justifyContent: 'center' }}>
					<h1 style={{ textAlign: "center" }}><LocalizedLabel text={{ de: "Preise", en: "Prices" }} /></h1>
				</Row>

				<Column>
					<LocalizedLabel text={{
						de:
							<>
								<Row {...card_style}>
									Alle Kostenvorabschläge berechne ich anhand von tatsächlichem (erwarteten) Aufwand und einem festen Stundensatz.<br />
									Dieser liegt derzeit zwischen 30 und 50 Eur die Stunde, je nach Anspruch des Projektes.<br />
									Beispielsweise rechne ich für CMS Erweiterungen 36 Eur die Stunde, und bei App Entwicklung mit 50 Eur die Stunde.<br />
								</Row>
								<Row {...card_style}>
									Bei kleineren Web-Projekten biete ich eine Geld-zurück Garantie, sollte Ihnen das Ergebnis nicht gefallen.<br />
									Bei Software und Apps hingegen muss ein Pflichtenheft ausgearbeitet werden.<br />
								</Row>
								<Column {...card_style}>
									Ich bin 7 Tage die Woche, inklusive Feiertage verfügbar.<br />
								</Column>
								<h2>In welcher Größenordnung kostet mich X?</h2>
								<Column {...card_style}>
								<h3>CMS Erweiterung :</h3>
									Eine simple CMS Erweiterung, wie zB ein spezielles Email generierendes Formular in Contao, oder eine Darstellung von Werten aus einem dritten Plugin in Wordpress, fängt bei ~8h Arbeit an. Dies schließt die Besprechung mit ein, sowie kleine Anpassungen zum Ende.
								</Column>
								<Column {...card_style}>
								<h3>App :</h3>
									Eine App für iOS und Android in einem hybriden Framework wie zB React Native oder Cordova ist sehr schwer abzuschätzen, liegt aber mindestens im 4-stelligen Bereich.
								</Column>


							</>
						, en: <span>Prices</span>
					}} />
				</Column>

			</Column>
		</Page>;
	}
}