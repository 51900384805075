import React from 'react';
import { Page } from '../Page';
import { Column } from '../../Column';
import { Row } from '../../Row';
import Header from '../../../Assets/Images/feature_graphic.svg'
import { Gallery } from '../../Gallery';
import { ResponsiveRowColumn } from '../../ResponsiveRowColumn';
import { LocalizedLabel } from '../LocalizedLabel';

export function Cordova()
{
	return <Page url="/cordova" container_style={{maxWidth:1000}}>
		
		<Column className="page_content" style={{ color: "var(--c_d)", position: 'relative', marginTop: 60,overflow:'initial', padding:0 }}>
			<LocalizedLabel text={{
				de:
				<div >
						<a rel="noreferrer" style={{ position: 'relative', zIndex: 1, height: 0, top: 22 }} href='https://play.google.com/store/apps/details?id=de.metropol&hl=it&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' target="_blank"><img height={70} alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/de_badge_web_generic.png' /></a>
						<a rel="noreferrer" style={{ position: 'relative', zIndex: 1, height: 0, top: 20 }} href='https://apps.apple.com/de/app/metropol-e/id1436488166' target="_blank"><img style={{ padding: 8 }} height={64} alt='Download on the App Store' src='Images/AppStore_black_de.svg' /></a>
						</div>,
				en:
					<div >
						<a rel="noreferrer" style={{ position: 'relative', zIndex: 1, height: 0, top: 22 }} href='https://play.google.com/store/apps/details?id=de.metropol&hl=it&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' target="_blank"><img height={70} alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' /></a>
						<a rel="noreferrer" style={{ position: 'relative', zIndex: 1, height: 0, top: 20 }} href='https://apps.apple.com/de/app/metropol-e/id1436488166' target="_blank"><img style={{ padding: 8 }} height={64} alt='Download on the App Store' src='Images/AppStore_black.svg' /></a>
					</div>
			}} style={{ width:'100%', display:'flex', justifyContent:'center', marginTop:-60}} />
		<Column style={{ width:"100%",height:"100%",overflowY:'auto',padding:8 }}>
		

			{/* <img style={{ height: 150, opacity: 1, objectFit: 'cover', objectPosition: "50% 50%" }} src={"Images/feature_graphic.svg"} loading={"lazy"} /> */}
			<Row style={{ justifyContent: 'center' }}>
				<h1>Metropol-E</h1>
			</Row>




			<div style={{ width: 'auto', textAlign: 'center' }} className="card">
				<LocalizedLabel text={{
					de:<span>"Metropol-E" ist eine App, die Tourismus and E-mobilität verbindet.<br/>Im Auftrag von Wolfsburg AG.</span>,
					en:<span>"Metropol-E" is an app for connecting the tourism and e-mobility industries.<br/>Comissioned by Wolfsburg AG.</span>,
				}}/>
				
			</div>
			<div className="card">
			<ResponsiveRowColumn style={{ alignItems: 'center', width:'auto' }}>
				<span>
					<h2><LocalizedLabel text={{de:'Details',en:'About'}}/></h2>
					<LocalizedLabel text={{de:<span>Ich arbeitete 2018 an diesem Projekt als Lead Developer in Kollaboration mit  <a rel="noreferrer" href={"https://www.mediendiele.de/"} target="_blank">Mediendiele UG</a>.<br />
					Das Ziel des Projektes war die Verbesserung von Tourismus in der Region durch den Ausbau der Charging-Infrastruktur für E-Fahrzeuge. Viele Ladestationen bieten nur wenig Ladeleistung an. Daher sollten Tourismus Routen angeboten werden, bei denen an solchen Ladesäulen geparkt werden konnte, während Zeit in den jeweiligen Tourismus Attraktionen der Gegend verbracht wurde.<br/>
					Das Projekt bestand darin, existierende Ladeinfrastruktur APIs mit unserem eigenen LAMP Server zu verbinden, ein php Backend mit visuellen Admin Kapazitäten bereitzustellen, eine iOS und Android App zu schreiben, so wie eine Frontend Webseite zu implementieren.<br />
					Leider hat das Projekt nicht abgehoben und wurde kurz nach der Veröffentlichung beendet.
					</span>,
					en:<span>
						I worked as lead developer on this project in 2018 in collaboration with <a rel="noreferrer" href={"https://www.mediendiele.de/"} target="_blank">Mediendiele UG</a>.<br />
				The goal of the overarching Metropol-E project was to improve the tourism experience of electronic vehicles by linking existing charging infrastructure to nearby tourist attractions. Most charging stations offered very little output, and were not suitable for fully recharging any batteries. Instead they would charge just enough to make it worth the travel, giving you a net even charge while following specific tourist routes and visiting the cafes, cinemas, museums etc along the way. 
				The project involved connecting existing charging-infrastructure-APIs with our custom LAMP server, creating a php backend with visual admin capabilities, an iOS and Android app, and a frontend website.<br />
				Unfortunately, the project did not catch on and was discontinued soon after completion.
					</span>}}/>
				
				</span>
				<Gallery images={["Images/Metro/metro-android1.png", "Images/Metro/metro-android2.png", "Images/Metro/metro-web1.png"]} style={{ maxHeight: 400 }} />
			</ResponsiveRowColumn>
			</div>
			<div className="card">
			<ul>
				<li>
				<LocalizedLabel text={{de:<span>Die Apps nutzen <a rel="noreferrer" href={"https://cordova.apache.org/"} target="_blank">Cordova</a>/PhoneGap.</span>,en:<span>The apps use <a rel="noreferrer" href={"https://cordova.apache.org/"} target="_blank">Cordova</a>/PhoneGap.</span>}}/>
				</li>
				<li>
				<LocalizedLabel text={{de:<span>Die <a rel="noreferrer" href={"https://www.metropol-e.info/ladepunkte-finden.html"} target="_blank">Webseite</a> nutzt <a rel="noreferrer" href={"https://contao.org"} target="_blank">Contao</a>.</span>,en:<span>The <a rel="noreferrer" href={"https://www.metropol-e.info/ladepunkte-finden.html"} target="_blank">website</a> uses <a rel="noreferrer" href={"https://contao.org"} target="_blank">Contao</a>.</span>}}/>
				</li>
				<li>
				<LocalizedLabel text={{de:"Die Karte basiert auf GoogleMaps (Ich empfehle mitlerweile weg von GoogleMaps).",en:"The map uses GoogleMaps (Which I no longer recommend)."}}/>
				</li>
			</ul>
			</div>
			<span>
				
			</span>
						<div style={{ minHeight: 12, width: 10 }} />


		</Column>
		</Column>


	</Page>;
}
