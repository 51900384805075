import React from "react";

export class MetaHelper
{
	static set_description(description:string)
	{
		$("#meta-description").attr("content",description);

	}

	static set_title(title:string)
	{
		document.title = title;
		console.log("Set title ", title);

	}
}