import { Row } from "../Row";
import React, { Component, ReactElement } from "react";
import { Data } from "../../Helpers/Main";
import CSS from "csstype"
import "./LocalizedLabel.scss";
import $ from "jquery";
import { merge_props } from "../../Helpers/ReactHelper";


export interface LocalizedLabel_State
{
	text:string|Node|Element|ReactElement<any,any>
}

export interface LocalizedLabel_Props
{
	text:{en:string|Node|Element|ReactElement<any,any>,de:string|Node|Element|ReactElement<any,any>,[key:string]:string|Node|Element|ReactElement<any,any>},
	style?:CSS.Properties,
}

export class LocalizedLabel extends Component<LocalizedLabel_Props,LocalizedLabel_State>
{
	static uid = 0;
	uid = LocalizedLabel.uid++;
	text : string|Node|Element|ReactElement<any,any> = "";

	constructor(props:LocalizedLabel_Props)
	{
		super(props);
		var language = Data.get('language', 'en');

		this.text = props.text[language];
	}

	componentDidMount()
	{
		Data.add_hook('language', "LocalizedLabel"+this.uid, () => { this.on_text_change() })
	}

	componentWillUnmount()
	{
		Data.remove_hook('language', "LocalizedLabel"+this.uid)
	}

	on_text_change()
	{
		var language = Data.get('language', 'en');
		this.text = this.props.text[language];
		
		if(typeof this.text == "object" || this.text === undefined)
		{
			this.forceUpdate();
		}
		else
		{
			document.getElementById("LocalizedLabel"+this.uid)!.innerHTML = this.text;
		}
	}

	render()
	{
		if(this.text == undefined)
			return <></>;
		return <span id={"LocalizedLabel"+this.uid}  {...merge_props({className:"localized_label"},this.props)}>
			{this.text}
		</span>
	}
}