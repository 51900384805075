import React from 'react';
import { Page } from '../Page';
import { Column } from '../../Column';
import { Row } from '../../Row';
import { Gallery } from '../../Gallery';

import Header from '../../../Assets/Images/feature_graphic.svg'
import { ResponsiveRowColumn } from '../../ResponsiveRowColumn';
import { LocalizedLabel } from '../LocalizedLabel';

export function Qyu()
{
	return <Page 
				url="/qyu" 
				container_style={{maxWidth:"min(1000px, 100vw)"}}
				title={{en:"Qyu : The Arena - A react native mobile game",de:"Qyu : The Arena - Ein React Native Handy Spiel"}}
				description={{en:"A react native mobile game based on the LitRPG book genre.",de:"Ein React Native Handy Spiel, basierend auf dem LitRPG Buch-Genre."}}
				
				>

		<Column className="page_content" style={{ color: "var(--c_d)", position: 'relative', marginTop: 60, overflow: 'initial', padding: 0 }}>
			<LocalizedLabel text={{
				de:
					<div >
						Diese Seite ist unübersetzt, da sie sich regelmäßig ändert.
					</div>
			}} style={{ width: '100%', display: 'flex', justifyContent: 'center', padding: 32, color: '#3399ff', background: 'aliceblue', filter: '' }} />

			<LocalizedLabel text={{
				de:
					<div >
						<a rel="noreferrer" style={{ position: 'relative', zIndex: 1, height: 0, top: 12 }} href='https://play.google.com/store/apps/details?id=com.qyu&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' target="_blank"><img width={200} alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/de_badge_web_generic.png' /></a>
					</div>,
				en:
					<div >
						<a rel="noreferrer" style={{ position: 'relative', zIndex: 1, height: 0, top: 12 }} href='https://play.google.com/store/apps/details?id=com.qyu&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' target="_blank"><img width={200} alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' /></a>
					</div>
			}} style={{ position: 'absolute', width: '100%', display: 'flex', justifyContent: 'center', top: -60 }} />
			<Column style={{ width: "100%", height: "100%", overflowY: 'auto', padding: 8 }}>

				<img alt="The player enters the Arena." style={{ height: 150, opacity: 1, objectFit: 'cover', objectPosition: "50% 50%" }} src={"Images/Qyu/feature_graphic.svg"} loading={"lazy"} />
				<Row style={{ justifyContent: 'center' }}>
					<h1>Qyu - The Arena</h1>

				</Row>



				<span style={{ textAlign: 'center' }} className="card">
					"Qyu - The Arena" is a mobile roguelike RPG.
				</span>
				<ResponsiveRowColumn style={{ alignItems: 'center' }}>
					<div className="card">
						<span>
							<h2>Blurb</h2>
							You play a character who finds himself in an arena in a different world.<br />
							A strange computerlike interface called "The System" manages every part of this reality.<br />
							It helps people gain skills, magically increases your strength after exercise, and generally mimics our known reality, all while offering the people infinite paths to power, knowledge and riches. For people in that world, the System is the fundamental force that drives reality.<br />
							You start in the Arena with no skills and no knowledge of the System, and have to survive increasingly harder rounds of combat.<br />
							Only by exploiting the System can you gain the power to eventually escape The Arena.<br />
						</span>
					</div>
					<Gallery images={["Images/Qyu/Screenshot_Land1.png", "Images/Qyu/Screenshot_Land2.png", "Images/Qyu/Screenshot_Land3.png"]} />
				</ResponsiveRowColumn>

				<div className="card">
					<span>
						The game is based on the novel genre <a rel="noreferrer" href={"https://en.wikipedia.org/wiki/LitRPG"} target="_blank">LitRPG</a>, which is about life in a game-like world. I've looked through the most popular works in the genre and am trying to extract what the "ideal" System should look like. The mechanics described in the books are often nothing like what exists in real games, often very unbalanced, unreasonably expensive on the CPU, or overly complex to implement. It's a fun challenge.<br />
					</span>
				</div>
				<div className="card">
					<span>
						This is the first mobile project I've ever released under my own name.<br />
						It's written in Javascript/Typescript and uses ReactNative.<br />
					</span>
				</div>
				<br />
				<h2 style={{textAlign:'center'}}>What's next? (v0.3)</h2>
				<p className="card" style={{textAlign:'center'}}>
					The next update will focus on making combat more realistic. The System should enhance existing physical actions and quantify states. It should not drive combat itself.<br />
					Activities should be greatly enhanced. This should allow the player to steer gameplay progression more easily (Less RNG).
				</p>

				<h3>New Combat System</h3>
				<ul>
					<li>Separate Graveyard</li>
					<li>Display floating messages for damage, status effects etc</li>
					<li>Simulate distance between combatants.</li>
					<li>Simulate bodies, calculate damage based on hitboxes, dodging, weakspots, etc.</li>
					<li>Detailled combat log.</li>
				</ul>

				<h3>Expand Activities</h3>
				<ul>
					<li>Allow for meaningful choices in the Activity system.</li>
					<li>Quests, including a main Quest that explains the premise behind the LitRPG genre.</li>
					<li>Jobs for int/wis/dex/agi</li>
				</ul>

				<h3>NPC interactions</h3>
				<ul>
					<li>Implement "relationship" variables.</li>
					<li>Implement {`{cha}`} charisma stat.</li>
					<li>Affect Merchant prices.</li>
					<li>Affect Arenamaster choices.</li>
				</ul>

				<h3>Other</h3>
				<ul>
					<li>Aura Skills.</li>
					<li>Ranged weapons and magic foci.</li>
					<li>Monsters use equipment.</li>
					<li>Non System bodily traits like "Scaly Skin".</li>
					<li>Crafting basics.</li>
					<li>Moddable monsters/skills for custom balancing.</li>
					<li>Optional Play Store Achievements.</li>
				</ul>
				<div style={{ minHeight: 12, width: 10 }} />

			</Column>
		</Column>


	</Page>;
}
