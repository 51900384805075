import React from 'react';
import { Page } from '../Page';
import { Column } from '../../Column';
import { Row } from '../../Row';
import Header from '../../../Assets/Images/feature_graphic.svg'
import { Gallery } from '../../Gallery';
import { ResponsiveRowColumn } from '../../ResponsiveRowColumn';
import { LocalizedLabel } from '../LocalizedLabel';

export function Unity()
{
	return <Page url="/unity">

		<Column className="page_content" style={{ color: "var(--c_d)", position: 'relative', marginTop: 60, overflow: 'initial', padding: 0 }}>
			<LocalizedLabel text={{
				de:
					<div >
						<a rel="noreferrer" style={{ position: 'relative', zIndex: 1, height: 0, top: 22 }} href='https://play.google.com/store/apps/details?id=de.arkiel.LJVSH' target="_blank"><img height={70} alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/de_badge_web_generic.png' /></a>
						<a rel="noreferrer" style={{ position: 'relative', zIndex: 1, height: 0, top: 20 }} href='https://apps.apple.com/al/app/ljv-sh/id1556501937' target="_blank"><img style={{ padding: 8 }} height={64} alt='Download on the App Store' src='/Images/AppStore_black_de.svg' /></a>
					</div>,
				en:
					<div >
						<a rel="noreferrer" style={{ position: 'relative', zIndex: 1, height: 0, top: 22 }} href='https://play.google.com/store/apps/details?id=de.arkiel.LJVSH' target="_blank"><img height={70} alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' /></a>
						<a rel="noreferrer" style={{ position: 'relative', zIndex: 1, height: 0, top: 20 }} href='https://apps.apple.com/al/app/ljv-sh/id1556501937' target="_blank"><img style={{ padding: 8 }} height={64} alt='Download on the App Store' src='/Images/AppStore_black.svg' /></a>
					</div>
			}} style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop:-60}} />
			<Column style={{ width: "100%", height: "100%", overflowY: 'auto', padding: 8 }}>


				{/* <img style={{ height: 150, opacity: 1, objectFit: 'cover', objectPosition: "50% 50%" }} src={"Images/feature_graphic.svg"} loading={"lazy"} /> */}
				<Row style={{ justifyContent: 'center' }}>
					<h1>LJV SH</h1>
				</Row>

				<span style={{ width: '100%', textAlign: 'center',height:'40px' }}>
				<LocalizedLabel className="card" text={{de:"\"LJV SH\" ist eine AR partner App zu gepaarten physischen Medien wie Brochüren.",en:"\"LJV SH\" is an AR companion app to specific physical media such as brochures."}}/>
			</span>
			<div className="card">
				<ResponsiveRowColumn style={{ alignItems: 'center' }}>
					<span>
						<h2><LocalizedLabel text={{de:"Details",en:"About"}}/></h2>
						<LocalizedLabel text={{
							de:<>Die App wurde in <a rel="noreferrer" href={"https://unity.com/"} target="_blank">Unity</a> estellt.<br />
					Ich entwickelte einen grundlegenden Prototypen, implementierte die AR Komponenten, gab technischen Support. Den Rest der Arbeit geschah in-haus bei </>,
							en:<>The app was created in <a rel="noreferrer" href={"https://unity.com/"} target="_blank">Unity</a>.<br />
					I created a basic prototype for the app, implemented the AR components, and provided technical support. The rest of the work was done in-house at </>}}/>
					 <a rel="noreferrer" href={"https://www.lwk-ratjen.de/startseite.html"} target="_blank">Lithographische Werkstaetten Kiel</a>.
				</span>
					{/* <Gallery images={["Images/Metro/metro-android1.png", "Images/Metro/metro-android2.png", "Images/Metro/metro-web1.png"]} style={{ maxHeight: 400 }} /> */}
				</ResponsiveRowColumn>
				</div>
				<span>

				</span>
				<div style={{ minHeight: 12, width: 10 }} />

			</Column>
		</Column>
	</Page>;
}
