import React from 'react';
import { Page } from '../Page';
import { Column } from '../../Column';
import { Row } from '../../Row';
import { Gallery } from '../../Gallery';

import Header from '../../../Assets/Images/feature_graphic.svg'
import { ResponsiveRowColumn } from '../../ResponsiveRowColumn';
import { LocalizedLabel } from '../LocalizedLabel';

export function CMS()
{


	return <Page url="/CMS">

		<Column className="page_content" style={{ color: "var(--c_d)", position: 'relative', marginTop: 60, overflow: 'initial', padding: 0 }}>
			<Column style={{ width: "100%", height: "100%", overflowY: 'auto', padding: 8 }}>
				<Row style={{ justifyContent: 'center' }}>
					<h1>CMS Extensions</h1>
				</Row>
				<span className="card" style={{  textAlign: 'center' }}>
					<LocalizedLabel text={{de:"Ich habe bisher für über ein Duzend Kunden CMS Erweiterungen entwickelt. Sowohl in traditionellen CMS wie Shopware, Joomla, Contao und Wordpress, als auch in headerless CMS wie Strapi, die Grundlagen sind immer die selben. Ich bin daher gerne auch bereit, mich mit neuen Umgebungen auseinanderzusetzen, wenn Ihr Projekt das braucht.",en:"I've worked on different CMS extensions for over a dozen clients by now. From well-known environments like Shopware, Joomla and Wordpress, to headerless ones like Strapi, to obscure ones like Contao, the basics are always the same. And I'm always ready to dive into new ones."}}/>
				</span>
				<ResponsiveRowColumn style={{ alignItems: 'center' }}>
					<span  className="card">
					<h2 style={{textAlign:'center'}}><LocalizedLabel text={{de:"Beispiele",en:"Examples"}}/></h2>
					<LocalizedLabel text={{de:"Links/Unten können Sie Beispiele von CMS Erweiterungen sehen, die ich für Kunden entwickelt habe. Aus Datenschutzgründen sind viele Details verschwommen dargestellt. Klicken Sie auf die Links, um die Live Seiten anzuschauen.",en:"On the right/bottom you will find images of some CMS extensions I've written. A lot of them contain sensitive data, which I've blurred or replaced just in case. Have a look at the real site to see how it actually looks."}}/><br/><br/>
					<a href="https://wogekiel.de/mietangebote.html" target="_blank" rel="noreferrer">WOGE</a> | <a href="https://www.sternlage.de/immobilien-angebote" target="_blank" rel="noreferrer">Sternlage</a><br/>
					<LocalizedLabel text={{de:"Immobilienvermarktungsseiten verwenden zentralisierte Immobilienmanagementsoftware. Alle Vermarktungsportale greifen auf diese Managementsoftware zu und implementieren das jeweilige Protokoll, zb OpenImmo, um Immobilien zwischen den Platformen zu synchronisieren. Für WOGE und Sternlage habe ich ein Modul geschrieben, dass die Immobilien synchronisiert, Thumbnails erstellt, und im Frontend darstellt, inklusive Kartenverortung.",en:"Real estate websites like this use a centralized real estate management software that synchronizes changes across all relevant real estate marketing platforms. The custom module fetches these changes, creates thumbnails, imports the items into a searchable database and finally displays them, including geolocation on a map."}}/><br/><br/>
					<a href="https://tanzschule-tessmann.de/index.php?option=com_content&view=article&id=4&Itemid=587" target="_blank" rel="noreferrer">Tanzschule Tessmann</a><br/>
					<LocalizedLabel text={{de:"Unter anderem habe ich ein Modul geschrieben, dass aus Formulardaten einen Gutschein in PDF Form generiert.",en:"Tanzschule Tessmann is a dancing school. Among other things, I've written a module that generates filled-out pdf vouchers from form inputs."}}/><br/><br/>
						
						
					</span>
					<Gallery images={[
						"Images/CMS/woge1.png",
						"Images/CMS/sternlage1.png",
						"Images/CMS/tessmann1.png"
						]} />
				</ResponsiveRowColumn>

			</Column>
		</Column>


	</Page>;
}
