import { Row } from "../Row";
import "./ThemeSwap.scss";
import sun from "../../Assets/Images/sun.svg"
import moon from "../../Assets/Images/moon.svg"
import React, { Component } from "react";
import { Data } from "../../Helpers/Main";
import { CookieHelper } from "../../Helpers/CookieHelper";

let set_css_var = function (name:string,value:any)
{
	document.documentElement.style.removeProperty(name);
					// update the element. Note: We are doing this on <html>, but varaibles can be scoped to ANY element
	document.documentElement.style.setProperty(name, value);
};

let set_dark_theme = function()
{
	console.log("Set dark theme");
	Data.set('theme','dark');
	CookieHelper.set_cookie('theme','dark');
	set_css_var('--theme','0%');
	set_css_var('--c_0','#000');
	set_css_var('--c_05','#0a0a0a');
	set_css_var('--c_1','#111');
	set_css_var('--c_2','#222');
	set_css_var('--c_3','#333');
	set_css_var('--c_4','#444');
	set_css_var('--c_5','#555');
	set_css_var('--c_6','#666');
	set_css_var('--c_7','#777');
	set_css_var('--c_8','#888');
	set_css_var('--c_9','#999');
	set_css_var('--c_a','#aaa');
	set_css_var('--c_b','#bbb');
	set_css_var('--c_c','#ccc');
	set_css_var('--c_d','#ddd');
	set_css_var('--c_e','#eee');
	set_css_var('--c_f','#fff');
	set_css_var('--background','#0a0a0a');
	set_css_var('--panel','#111');
};

let set_light_theme = function()
{
	console.log("Set light theme");
	Data.set('theme','light');
	CookieHelper.set_cookie('theme','light');
	set_css_var('--theme','100%');
	set_css_var('--c_0','#fff');
	set_css_var('--c_05','#f9f9f9');
	set_css_var('--c_1','#eee');
	set_css_var('--c_2','#ddd');
	set_css_var('--c_3','#ccc');
	set_css_var('--c_4','#bbb');
	set_css_var('--c_5','#aaa');
	set_css_var('--c_6','#999');
	set_css_var('--c_7','#888');
	set_css_var('--c_8','#777');
	set_css_var('--c_9','#666');
	set_css_var('--c_a','#555');
	set_css_var('--c_b','#444');
	set_css_var('--c_c','#333');
	set_css_var('--c_d','#222');
	set_css_var('--c_e','#111');
	set_css_var('--c_f','#000');
	set_css_var('--background','#f3fdff');
	set_css_var('--panel','#fff');

};

export class ThemeSwap extends Component
{
	light_symbol = React.createRef<HTMLDivElement>();
	night_symbol = React.createRef<HTMLDivElement>();
	uid = 0;
	change_handle = 0;

	constructor(props:any)
	{
		super(props);
		if(CookieHelper.get_cookie("theme") == 'light')
		{
			set_light_theme();
		}
		this.state = {};
	}	

	// change_alpha = () => 
	// {
	// 	//if(this.night_symbol.current)
	// 	//	this.night_symbol.current!.style.filter = `brightness(${(Data.get('theme') != 'dark' ? 0 : (Math.random() * 0.6 + 0.7)).toString()})`;
	// 	if(this.light_symbol.current)
	// 		this.light_symbol.current!.style.filter = `brightness(${(Data.get('theme') != 'light' ? 1 :( Math.random() * 1 + 1)).toString()})`;
	// }

	componentDidMount()
	{
		Data.add_hook('theme',this.uid,()=>{this.setState({})})
		//this.change_handle = setInterval(this.change_alpha,100) as any as number;
	}
  
	componentWillUnmount()
	{
	  	Data.remove_hook('theme',this.uid)
		  clearInterval(this.change_handle);
	}

	render()
	{
		var theme = Data.get('theme','dark');
		return <Row className="theme_swap">
			{/* <span>Theme&ensp;</span> */}
			<div ref={this.night_symbol} onClick={set_dark_theme} className={"theme_swap_swatch dark" + (theme == 'dark' ? ' active' : "")} >
				<img alt="Switch to dark theme" src={moon} style={{height:'20px'}}/>
				</div> 
			<div ref={this.light_symbol} onClick={set_light_theme} className={"theme_swap_swatch light" + (theme == 'light' ? ' active' : "")}>
				<img alt="Switch to light theme" src={sun} style={{height:'20px',filter:"brightness(100%)"}}/>
				</div> 
		</Row>
	}
}