import { Component, CSSProperties } from "react";
import "./Footer.scss";
import { merge_props } from '../../Helpers/ReactHelper';
import arrow from '../../Assets/Images/back-arrow.svg'
import history from '../../Helpers/History';
import $ from 'jquery';
import './BackgroundAnimatedWater.scss'
import React from "react";
import { perlin } from "../../Helpers/Perlin";
import { Data } from "../../Helpers/Main";

export interface AnimatedBackgroundState
{
	data: number[][],
	char_width: number,
	char_height: number,
	focused: boolean,
	time: number,
	theme: number,
	intro_animation: number,
}

export interface AnimatedBackgroundProps
{
	style?:CSSProperties,
	paused?:boolean|(()=>boolean)
}

export type Color = [r:number,g:number,b:number,a:number];

const hex = ['0','1','2','3','4','5','6','7','8','9','a','b','c','d','e','f']

const hex2 = Array(256);

for(let i = 0; i < 16; i++)
{
	for(let i2 = 0; i2 < 16; i2++)
	{
		hex2[i*16 + i2] = hex[i] + hex[i2]
	}	
}

export function byte_to_hex(byte:number) : string
{
	return hex2[byte];
}

export function rgba(color:Color)
{
	return '#' + byte_to_hex(color[0]) + byte_to_hex(color[1]) + byte_to_hex(color[2]) + byte_to_hex(color[3]);
}

export function interpolate_colors(a:Color,b:Color,v:number) : Color
{
	return [ 
		Math.round(v * b[0] + (1-v) * a[0]),
		Math.round(v * b[1] + (1-v) * a[1]),
		Math.round(v * b[2] + (1-v) * a[2]),
		Math.round(v * b[3] + (1-v) * a[3])
	];
}

export function ease_in_out(x: number): number {
	return -(Math.cos(Math.PI * x) - 1) / 2;
	
	}

async function wait(time:number)
{
	return new Promise((resolve,reject)=>{
		setTimeout(()=>{
			resolve(undefined);
		},time)
	})
}

export class AnimatedBackground extends Component<AnimatedBackgroundProps>
{
	static update_rate = 0.5;

	static colors : {[key:string]:[Color,Color]} = {
		stars : [[255,255,255,255],[255,255,255,255]],
		sky : [[0,0,150,55],[0,204,255,55]],
		horizon : [[10,100,255,20],[0,204,255,17]],
		water: [[0,50,240,255],[0,204,255,255]],
		space: [[0,0,0,0],[0,0,0,0]],
		deep_sea:[[0,0,0,0],[0,0,0,0]],
	}

	static get_colors(theme:number) : {[key:string]:Color}
	{
		let result : {[key:string]:Color} = {};

		for(let k in AnimatedBackground.colors)
		{
			result[k] = interpolate_colors(AnimatedBackground.colors[k][1],AnimatedBackground.colors[k][0],theme);
		}

		return result;
	}

	state: AnimatedBackgroundState;
	_update_id: any = -1;
	canvas = React.createRef<HTMLCanvasElement>();
	height:number = 0;
	width:number = 0;
	real_width:number = 0;

	unmounterd = false;
	constructor(props: any)
	{
		super(props);

		this.state = {
			data: [],
			char_width: 0,
			char_height: 0,
			focused: true,
			time: Date.now()/1000,
			theme: -1,
			intro_animation: -1,
			bubbles: [],
		} as any;
	}

	get_font_size(canvas:HTMLCanvasElement):number
	{
		let result = 8 + Math.floor(Math.max(canvas.clientWidth, canvas.clientHeight) / 128) / 2; // Halfway between 16 and responsive
		// the result must divide canvas.clientHeight evenly, else we get empty spaces if multiple backgrounds stack in a column
		let aspect = canvas.clientHeight / result; // eg 233.124 means we need to reduce all 233 by 0.124 in total, so each one by 0.124/233
		result = canvas.clientHeight / Math.floor(aspect);
		//console.log(result,canvas.clientHeight / result,aspect);
		return result;
	}

	init(canvas:HTMLCanvasElement)
	{
		let detect_font_size = $(`<span style="position:absolute;font-family: monospace;font-size:${this.get_font_size(canvas)}px;">~</span>`).appendTo($(`body`));
		this.state.char_width = detect_font_size.width()!
		this.state.char_height = detect_font_size.height()!
		this.state.theme = -1;
		canvas.width = canvas.clientWidth;
		canvas.height = canvas.clientHeight;
		var FONT_SIZE = this.get_font_size(canvas);
		this.height = Math.ceil(canvas.height! / FONT_SIZE)
		this.width = Math.ceil(canvas.width! / this.state.char_width)
		this.real_width = canvas.width! / this.state.char_width;

		detect_font_size.remove();
	}

	componentDidMount()
	{
		$(window).on("blur", this.on_blur)
		$(window).on("focus", this.on_focus)
		$(window).on("resize", this.on_resize)

		this.on_update();
	}

	componentWillUnmount()
	{
		$(window).off("blur", this.on_blur)
		$(window).off("focus", this.on_focus)
		$(window).off("resize", this.on_resize)
	}

	on_blur = () =>
	{
		this.state.focused = false;
	}

	on_focus = () =>
	{
		this.state.focused = true;
	}

	on_resize = () =>
	{
		this.setState({ char_width: 0, char_height: 0 });
	}

	_time : number = 0;
	first : boolean = true;
	on_update = () =>
	{
		if (this.unmounterd)
			return;

		let time = Date.now()/1000 - this._time;
		let transitioning = ( Date.now() - 500)  < Data.get("last_transition",0);
		let is_paused = typeof(this.props.paused) == "function" ? this.props.paused() : this.props.paused
		if( !this.first && (is_paused || transitioning) )
		{
			this._time = Date.now()/1000;
		}
		else if (this.state.focused && time > (this.constructor as typeof AnimatedBackground).update_rate)
		{
			this.first = false;
			this.state.time += time; // fixed framerate, precise delta time doesn't matter here.

			this.update_data();

			this._time = Date.now()/1000;
		}

		
		
		
		window.requestAnimationFrame(this.on_update);
	}


	// test absolute vs relative
	update_data()
	{
		
	}

	render()
	{
		return <canvas ref={this.canvas} className="background-animated-water-canvas" style={this.props.style || {}}></canvas>
	}
}
