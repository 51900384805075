import React, { SyntheticEvent } from 'react'
import { merge_props } from '../Helpers/ReactHelper'
import { Column } from './Column'
import './FullscreenGallery.scss'
import $ from 'jquery';
import { BaseProps } from "./BaseProps";
import history from "../Helpers/History";
import { Gallery } from './Gallery';
import appIcon from "../Assets/Images/app.svg"
import crossIcon from "../Assets/Images/cross.svg"
import { obj } from '../Helpers/TypescriptHelper';


export interface FullscreenGallery_Props extends BaseProps
{
}

export interface FullscreenGallery_State
{
	index: number;
	fullscreen: boolean;
	overview: boolean;
	gallery?: Gallery;
	target_containers: HTMLImageElement[]
}

export class FullscreenGallery extends React.Component<FullscreenGallery_Props, FullscreenGallery_State>
{
	static instance : FullscreenGallery;
	state:FullscreenGallery_State;
	page = ["",""]

	constructor(props: FullscreenGallery_Props)
	{
		super(props);
		this.state = { index: 0, fullscreen: false, overview: false, target_containers: []};
		FullscreenGallery.instance = this;
	}

	get_images()
	{
		return this.state.gallery?.props.images || [];
	}


	updateDimensions = () => 
	{
		// let container = $("#" + (this.props.id || "FullscreenGallery" + this.id));
		// let children = container.find('.FullscreenGallery_img');
		// let max_height = 0;
		// for (let i = 0; i < children.length; i++)
		// {
		// 	max_height = Math.max(max_height, children[i].clientHeight);
		// }
		// if (max_height != 0)
		// 	container.css("min-height", max_height);
		this.forceUpdate();
	};

	componentDidMount()
	{
		window.addEventListener('resize', this.updateDimensions);
		this.updateDimensions();

		

		//this.show();
	}

	componentWillUnmount()
	{
		window.removeEventListener('resize', this.updateDimensions);
	}

	componentDidUpdate()
	{
		console.log("RENDERED");
		for(let i = 0; i < (this.state.gallery?.props.images.length || 0);i++)
		{
			this.onImgLoad(i,{target:$("#fullscreen_gallery_img_target_" + i)[0]}) 
		}
	}

	// while the FullscreenGallery is in full-screen mode, disable traditional back button functionality
	disable_back = ()=>
	{
		history.push(this.page[1]);
		console.log("PUSH")
		this.hide();
	}

	hide = () =>
	{
		// let FullscreenGallery = $("#fullscreen_gallery"); 
		// FullscreenGallery.fadeOut(500);
		// this.state.fullscreen = false;
		window.removeEventListener("popstate", this.disable_back);
		
		// let gallery = this.state.gallery!;
		// let gallery_container = $(`#gallery` + gallery.id)[0];
		// let images = this.get_images();

		if(this.state.gallery)
		{
			this.state.gallery.setState({index:this.state.index});
		}
		this.setState({fullscreen:false});



		// for(let i = 0; i < images.length; i++)
		// {
		// 	let real_image = $("#fullscreen_gallery_img_" + i);
		// 	real_image.width(gallery_container.clientWidth);
		// 	real_image.height(gallery_container.clientHeight);
		// 	real_image.css({transform:`translate(${gallery_container.getBoundingClientRect().left}px,${gallery_container.getBoundingClientRect().top - 28}px)`});
		// }
	}

	show = async (gallery?:Gallery) =>
	{
		this.state.gallery = gallery;
		//gallery = this.state.gallery!;
		if(gallery)
		{
			
			let gallery_container = $(`#gallery` + gallery.id)[0];
			let images = this.get_images();
			for(let i = 0; i < images.length; i++)
			{
				this.dimensions[images[i]] = await this._get_image_dimensions(images[i]);
				// let real_image = $("#fullscreen_gallery_img_" + i);
				// let transition = real_image.css("transition");
				// real_image.css("transition",";");
				// real_image.width(gallery_container.clientWidth);
				// real_image.height(gallery_container.clientHeight);
				// real_image.css({transform:`translate(${gallery_container.getBoundingClientRect().left}px,${gallery_container.getBoundingClientRect().top - 28}px)`});
				// real_image.css("transition",transition);	
			}
			console.log(this.dimensions);
		}
		this.setState({gallery,overview:false,fullscreen:true,index:gallery!.state.index});

		
		window.addEventListener("popstate", this.disable_back);

		this.page = [document.title,  window.location.pathname, window.location.href];
		
		// let FullscreenGallery = $("#fullscreen_gallery"); 
		// FullscreenGallery.fadeIn(500,"swing",()=>{
		// 	this.setState({fullscreen:true});
		// });
	}

	toggle_overview = ()=>
	{
		this.setState({overview:!this.state.overview});
	}

	show_overview()
	{
		this.setState({overview:true});
	}

	show_detailled_view(index=this.state.index)
	{
		this.setState({overview:false,index});
	}

	prev_image = () =>
	{
		this.setState({index:Math.max(this.state.index -1,0)});
	}

	next_image = () => 
	{
		this.setState({index:Math.min(this.state.index + 1,(this.state.gallery?.props.images.length || 0) - 1)});
	}

	onImgLoad(i:number,{target}:{target:any}) 
	{
		// let image = target as HTMLImageElement;
		// this.state.target_containers[i] = image;

		// let real_image = $("#fullscreen_gallery_img_" + i);
		// real_image.width(image.clientWidth);
		// real_image.height(image.clientHeight);
		// real_image.css({transform:`translate(${image.getBoundingClientRect().left}px,${image.getBoundingClientRect().top - 28}px)`});

    }

	dimensions : obj<[number,number]> = {};

	async _get_image_dimensions(image:string) : Promise<[number,number]>
	{
		let promise = new Promise<[number,number]>((resolve)=>{
			const img = new Image();
			img.onload = function() {
				resolve([(this as any).width,(this as any).height]);
			}
			img.src = image;
		});

		return promise;
	}

	get_image_dimensions(image:string) : [number,number]
	{
		return this.dimensions[image] || [0,0];
	}

	pack_images2(images:obj<[number,number]>)
	{
		let result : obj<[number,number,number,number,number,number]> = {};

		let size : [number,number] = [0,0];
		let imgs = Object.entries(images);
		let count = imgs.length;
		let rows = Math.ceil(Math.sqrt(count) * window.innerHeight/window.innerWidth);
		let cols = Math.ceil(count / rows);

		let max = [0,0];
		for(let i = 0; i < count; i++)
		{
			if(imgs[i][1][0] > max[0])
				max[0] = imgs[i][1][0];

			if(imgs[i][1][1] > max[1])
				max[1] = imgs[i][1][1];
		}

		const [max_width,max_height] = max;
		const expected_height = rows * max_height;
		const expected_width = cols * max_width;

		size = [expected_width, expected_height];

		const block_width = window.innerWidth/cols;
		const block_height = window.innerHeight/rows;

		let window_aspect = window.innerWidth/window.innerHeight;
		let expected_aspect = expected_width/expected_height;
		
		

		// let row_width : number[] = new Array(rows);
		// row_width.fill(0,0,rows);
		// for(let i = 0; i < count; i++)
		// {
		// 	let [,v] = imgs[i];
		// 	let y = Math.floor(i / rows);
		// 	row_width[y] += v[0];
		// }
		
		const padding = 0.95;
		for(let i = 0; i < count; i++)
		{
			let [k,v] = imgs[i];

			let y = Math.floor(i / cols);
			let x = i - (y * cols);

			let width = v[0];
			let height = v[1];

			let shrink = Math.min( block_width/width,block_height/height); 

			let x_offset = y == (rows - 1) ? ((count % cols) ) * block_width : 0


			result[k] = [ x * block_width + (block_width - width + x_offset)/2,  y * block_height + (block_height-height)/2  , width, height,shrink * padding,shrink * padding]; //v[0]/(max_width*shrink),v[1]/max_height // + Math.max(0,(window.innerHeight*shrink-max_height * rows) / 2)
		}

		return {rects:result,size};
	}

	unpack_images(images:obj<[number,number]>)
	{
		let block_width = window.innerWidth;
		let block_height = window.innerHeight;
		let size : [number,number] = [block_width * Object.keys(images).length, block_height];

		let result : obj<[number,number,number,number,number,number]> = {};

		let offset = 0;
		for(let k in images)
		{
			const [width,height] = images[k];
			let scale = Math.min( block_width/width , block_height/height ); 
			result[k] = [offset + (block_width - width)/2, + (block_height - height)/2,width,height,scale,scale];
			offset += block_width; 
		}

		return {rects:result,size,};
	}

	render() 
	{
		// TODO :
		// Rerender on window size change.
		// Center arrows
		// hide arrows at 0 / size-1
		// buttons not visible over white backgrounds : Use round backdrops or change color to filter.
		// small width doesn't work on full-screen
		// small width should flip to column

		let gallery = this.state.gallery;
		let gallery_container = $(`#gallery` + gallery?.id)[0];

		const padding = 32;

		let images = this.get_images();

		let image_sizes : obj<[number,number]> = {};
		for(let i = 0; i < images.length; i++)
		{
			image_sizes[images[i]] = this.get_image_dimensions(images[i]);
		}

		
		let { fullscreen, overview, index } = this.state;

		let packed_images = overview ? this.pack_images2(image_sizes) : this.unpack_images(image_sizes);
		
		return <div id="fullscreen_gallery" style={{background:"black", position:'absolute', height:'100%', width:'100%', left:0, top:0, zIndex:6,   pointerEvents:fullscreen?'auto':'none', opacity:fullscreen?1:0,transition: 'opacity 0.5s'}}>
				
 				<div id='fullscreen_gallery_content' style={{position:'absolute', top:0, left:0, width:"100%",height:"100%", overflowY:'auto', overflowX:'hidden'}}>
					 {
						 images.map((v, i) =>
						 {
						 	return <img
								key={"fullscreen_gallery_img_" + i}
								className={"fullscreen_gallery_img " + (overview ? "clickable" : "")}
								id={"fullscreen_gallery_img_" + i}
								src={v}
								loading="lazy"
								alt=""
								style={
									{
										transform:`translate(${packed_images.rects[v][0] - ( !overview ? index * window.innerWidth : 0)}px,${packed_images.rects[v][1]}px) scale(${packed_images.rects[v][4]},${packed_images.rects[v][5]})`,//(fullscreen || !gallery_container)?`translate(${gallery_container.getBoundingClientRect().left}px,${gallery_container.getBoundingClientRect().top - 28}px)`,
										
										width:v[0],
										height:v[1],
										transition:"transform 1s"
										
									}
								}
								{...(overview?{onClick:this.show_detailled_view.bind(this,i)}:{})}
							 />
						 })
					 }
				 </div>
				 {
					 this.state.index != 0 &&
						<div id='fullscreen_gallery_prev_button' onClick={this.prev_image} className="gallery_nav_button" style={{left: 0, opacity:overview ? 0 : 1, top:'calc(50vh -  40px)',padding:20}}>{"<"}</div>
				 }
				 {
					 this.state.index < (images.length - 1) && 
				<div id='fullscreen_gallery_next_button' onClick={this.next_image} className="gallery_nav_button" style={{right: 0, opacity:overview ? 0 : 1, top:'calc(50vh -  40px)',padding:20}}>{">"}</div>
				 }
				<div style={{background:"var(--c_5)", position:'absolute',right:0,top:0, width : 144, height:72, padding:16, borderTopLeftRadius:16, borderBottomLeftRadius:16}}>
				<img alt="View all images" className="gallery_nav_button" onClick={this.toggle_overview} src={appIcon} style={{position:'absolute',right: 60,top:16,padding:"0px 16px", height:40}}/>
				<img alt="Close gallery" id='fullscreen_gallery_close_button'  onClick={this.hide} className="gallery_nav_button" src={crossIcon} style={{position:'absolute',right: 0,top:16,padding:"0px 16px", height:40}}/>
				</div>
		</div>
	}

}