import { Component } from "react";
import "./BackButton.scss";
import {merge_props} from '../../Helpers/ReactHelper';
import arrow from '../../Assets/Images/back-arrow.svg'
import history from '../../Helpers/History';

export class BackButton extends Component
{
	render()
	{
		var active = ("/" != window.location.pathname ? "active" : "");
		return <div onClick={()=>{active && ( history.go_back())}} {...merge_props({className: "back-button " + active},this.props)} >
			<img alt="Go back" style={{height:'100%',margin:'auto'}} src={arrow}/>
			{this.props.children}
		</div>
	}
}
