
import React from 'react';
import './App.scss';

import { Router, Route, Switch } from 'react-router-dom';
import { Website } from './Web/Website';
import history from '../Helpers/History';

import $ from 'jquery';

window.$ = $;

$(window).on("resize",()=>{
	let vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty('--vh', `${vh}px`);
})

function App() 
{
	let vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty('--vh', `${vh}px`);
	return (
		<Router history={history}>
			<Switch>
				<Route key="web" path="/" component={Website} />
			</Switch>
		</Router>
		
	);
}

export default App;
