import React from 'react';
import { Page } from '../Page';
import { Column } from '../../Column';
import { Row } from '../../Row';

export function Profession()
{
	return <Page url="/Profession">
		<Column className="page_content" style={{ color: "var(--c_d)" }}>
			<Row style={{ justifyContent: 'center' }}>
				<h1>Bin ich der richtige?</h1>
			</Row>
			<Row style={{ width: "100%", justifyContent: "center" }}>
				<Column>
					<Row style={{ justifyContent: 'center' }}>
						<h3>Was ich bin und tue :</h3>
					</Row>
					<Row>
						JS Web-Apps, zB in React.
					</Row>
					<Row>
						Node.js Module.
					</Row>
					<Row>
						Frontend und Backend Web Komponenten in beliebigen Umgebungen und Sprachen.
					</Row>
					<Row>
						Hybride Apps für Android, iOS, Windows, MacOs und Linux.
					</Row>
					<Row>
						Unity3d und Godot Apps.
					</Row>
				</Column>
				<Column style={{ width: "80%" }}>
					<Row style={{ justifyContent: 'center' }}>
						<h3>Was ich nicht bin :</h3>
					</Row>
					<Row>
						Ich bin kein graphischer Designer.<br />
						Ich erstelle oft graphische Module, und implementiere Designdocs.Aber ich erstelle keine ganzen Templates für Webseiten ohne Mock-ups oder Designdokumente.Siehe diese Webseite für meine Limits: )
					</Row>
					<Row>
						Ich bin kein Serveradministrator.
					</Row>
					<Row>

					</Row>
				</Column>
						// TODO : Insert page before w/ "money on delivery, 7days a week email support, bugfixes within 1 month for free "
			</Row>
		</Column>
	</Page>;
}
