import React, { Component } from "react";

export class AntiCrawlerText extends Component<{children:string}>
{
	render()
	{
		let content = [];
		for(let i = 0; i < this.props.children.length; i++)
		{
			content[i] = <span key={i} style={{opacity:0.99}}>{this.props.children[i]}</span>;
		}
		// @ts-ignore
		if(!(this.props.children.length))
			content = [this.props.children];
		return [
			<span key="_1" style={{ marginRight: '12px',color:"var(--panel)",userSelect:'none',width:"0%",height:"0%", display:'inline-block' }} aria-hidden={true}>0@0</span>,
			...content,
			<span key="_2" style={{ marginLeft: '12px',userSelect:'none',color:"var(--panel)",width:"0%",height:"0%", display:'inline-block' }} aria-hidden="true">0@0</span>
		]
	}
}