import React,{Component, RefObject} from 'react'
import './Row.scss'
import {merge_props} from '../Helpers/ReactHelper';


export interface RowProps extends Omit<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,"ref">
{
  ref ?: RefObject<Row>
}

export class Row extends Component<RowProps>
{
	ref = React.createRef();
	
  render() 
  {

    var result = <div ref={this.ref} {...merge_props({className:"row"},this.props)}>{this.props.children}</div>;

    

    return result;
  }
}